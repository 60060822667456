import React, { useRef, useEffect } from 'react';
import { Suspense, lazy } from 'react';
import Loading from 'components/loading';
import { Button, Box, Flex, VStack, Text, Image, HStack } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import backIcon from 'assets/images/backicon.svg';
import Permissions from 'utils/permissions';
import { useStore } from 'store';
import { useParams, useNavigate } from 'react-router-dom';
import { Routes } from 'constant';
import { partnermembersColumn } from 'data/partnermembers';

const DynamicTable = lazy(() => import('components/DynamicTable'));
const PartnerMembers = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    AppStore: { searchQuery },
    PartnerStore: {
      toggleAddPartnerMember,
      isShowAddPartnerMember,
      getSinglePartner,
      partnerMembersAdmin,
      getPartnerMembersAdmin,
      setPage,
      setLimit,
      page,
      limit,
      partnerMembersCount
    },
    AuthStore
  } = useStore();
  const permissions = new Permissions(AuthStore);
  const pagRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!isShowAddPartnerMember) {
      getPartnerMembersAdmin(id as string);
      getSinglePartner(id as string);
    }
  }, [isShowAddPartnerMember, id]);

  useEffect(() => {
    getPartnerMembersAdmin(id as string);
  }, [id, limit, page, searchQuery]);

  return (
    <Suspense fallback={<Loading />}>
      <Box py=".6rem" w="100%">
        <VStack w="100%">
          <HStack w="100%" justify="space-between" align="center" mt="1.5rem">
            <Flex gap=".5rem" align="center" justify="left" w="100%">
              <Image
                onClick={() => navigate(Routes.AdminPartners)}
                src={backIcon}
                alt="back"
                loading="lazy"
                cursor="pointer"
              />
              <Text
                fontSize={{
                  base: '14px',
                  md: '16px'
                }}
                fontWeight="550"
                color="#000000"
              >
                Member address | {partnerMembersAdmin?.length}
              </Text>
            </Flex>
            {permissions.hasFeatureAccess('CanAddPartnerMembers') && (
              <Flex justify="end" w="100%">
                <Button
                  alignSelf="flex-end"
                  w={{ base: '10%', md: '25%' }}
                  width="180px"
                  height="40px"
                  size="md"
                  px="2rem"
                  name="thirdPartyOrder"
                  onClick={() => {
                    toggleAddPartnerMember('', true);
                  }}
                >
                  <Text fontSize={{ base: '12px', md: '16px' }}>Add Member</Text>
                </Button>
              </Flex>
            )}
          </HStack>
          {permissions.hasFeatureAccess('CanViewPartnerMembers') && (
            <>
              <Box
                w="100%"
                bg="#ffffff"
                maxH="60%"
                mt=".5rem"
                borderRadius="8px"
                overflowY="auto"
                overflowX="auto"
                fontSize={{ base: '12px', md: '14px' }}
                border="1px solid #EDEDED"
                ref={pagRef}
              >
                <DynamicTable
                  data={partnerMembersAdmin}
                  columns={partnermembersColumn}
                  serverVariables={{
                    isServer: true,
                    setCurrentPage: setPage,
                    setPageLimit: setLimit,
                    currentPage: page,
                    pageLimit: limit
                  }}
                  totalCount={partnerMembersCount}
                />
              </Box>
            </>
          )}
        </VStack>
      </Box>
    </Suspense>
  );
};

export default observer(PartnerMembers);
