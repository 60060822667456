import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

// layouts
import AdminLayout from 'layouts/adminLayout';
import AccountGuard from 'guards/account.guard';
import { RedirectTo } from './util.route';
import { withRoleGuard } from 'guards/role.guard';

// components
const AdminHome = lazy(() => import('domains/adminDashboard'));
const AdminDashboard = lazy(() => import('domains/adminDashboard/dashboard'));
const AdminCustomer = lazy(() => import('domains/adminDashboard/customer'));
const CustomerHistory = lazy(() => import('domains/adminDashboard/customer/customerhistory'));
const PlaceOrderCustomer = lazy(() => import('domains/adminDashboard/customer/placeorder'));
const PlaceOrderPartnerMember = lazy(
  () => import('domains/adminDashboard/partners/members/placeorder')
);
const AdminOrders = lazy(() => import('domains/adminDashboard/orderpage'));
const AdminServices = lazy(() => import('domains/adminDashboard/services'));
const AdminOrderList = lazy(() => import('domains/adminDashboard/orderpage/components/OrderList'));
const AdminSubscriptionList = lazy(
  () => import('domains/adminDashboard/orderpage/components/subscription/SubscriptionList')
);
const AdminOrderSummaryPage = lazy(
  () => import('domains/adminDashboard/orderpage/components/OrderSummary')
);
const AdminOrderDetails = lazy(
  () => import('domains/adminDashboard/orderpage/components/OrderDetails')
);
const AdminOrderActivities = lazy(
  () => import('domains/adminDashboard/orderpage/components/OrderActivity')
);

const AdminConsultationService = lazy(() => import('domains/adminDashboard/services/consultation'));

const AdminWellnessHistory = lazy(
  () => import('domains/adminDashboard/services/wellness/WellnessHistory')
);
const AdminWellnessDetails = lazy(
  () => import('domains/adminDashboard/services/wellness/WellnessDetails')
);

const AdminConsultationSummaryPage = lazy(
  () => import('domains/adminDashboard/services/consultation/ConsultationSummary')
);
const AdminConsultationDetails = lazy(
  () => import('domains/adminDashboard/services/consultation/ConsultationDetails')
);
const AdminConsultationActivities = lazy(
  () => import('domains/adminDashboard/services/consultation/ConsultationActivity')
);

const AdminInventory = lazy(() => import('domains/adminDashboard/inventory'));
const AdminRunners = lazy(() => import('domains/adminDashboard/runners'));
const AdminRoles = lazy(() => import('domains/adminDashboard/runners/components/RolesList'));
const AdminPartners = lazy(() => import('domains/adminDashboard/partners'));
const AdminCoupon = lazy(() => import('domains/adminDashboard/coupon'));
const AdminCouponHistory = lazy(() => import('domains/adminDashboard/coupon/CouponHistory'));
const TransactionHistory = lazy(
  () => import('domains/adminDashboard/partners/components/TransactionHistory')
);
const WalletHistory = lazy(
  () => import('domains/adminDashboard/customer/walletDetails/WalletHistory')
);
const PartnerOrderHistory = lazy(() => import('domains/adminDashboard/partnerHistory'));
const CustomerAffiliateOrders = lazy(
  () => import('domains/adminDashboard/customer/customerAffiliates')
);
const CreateNewRole = lazy(() => import('domains/adminDashboard/runners/components/CreateNewRole'));
const RolePermissions = lazy(
  () => import('domains/adminDashboard/runners/components/RolePermissions')
);
const AdminCampaign = lazy(() => import('domains/adminDashboard/campaign'));
const PharmacyRequestHistory = lazy(
  () => import('domains/adminDashboard/partners/components/PharmacyRequestHistory')
);

import CheckoutDeliveryAddress from 'domains/adminDashboard/customer/placeorder/CheckoutDeliveryAddress';
import PartnerMembers from 'domains/adminDashboard/partners/members/PartnerMembers';
import PartnerMemberOrderHistory from 'domains/adminDashboard/customer/customerhistory';
import PartnerOrderCheckoutAddress from 'domains/adminDashboard/partners/members/placeorder/PartnerOrderCheckoutAddress';

const routes: RouteObject[] = [
  {
    path: '',
    element: (
      <AccountGuard>
        <AdminLayout />
      </AccountGuard>
    ),
    children: [
      {
        path: '',
        element: <AdminHome />,
        children: [
          {
            index: true,
            element: <RedirectTo path="/admin/dashboard" />
          },
          {
            path: 'dashboard',
            element: <AdminDashboard />
          },
          {
            path: 'subscription/:orderNumber/summary',
            element: <AdminOrderSummaryPage />,
            children: [
              {
                path: 'details',
                element: <AdminOrderDetails />
              },
              {
                path: 'activities',
                element: <AdminOrderActivities />
              }
            ]
          },
          {
            path: 'orders',
            element: withRoleGuard(AdminOrders, 'Orders'),
            children: [
              {
                index: true,
                element: <RedirectTo path="/admin/orders/all" />
              },
              {
                path: 'all',
                element: <AdminOrderList stage="all" />
              },
              {
                path: 'customer-success',
                element: <AdminOrderList stage="customer-success" />
              },
              {
                path: 'partner-success',
                element: <AdminOrderList stage="partner-success" />
              },
              {
                path: 'vendor-management',
                element: <AdminOrderList stage="vendor-management" />
              },
              {
                path: 'pick-up',
                element: <AdminOrderList stage="pick-up" />
              },
              {
                path: 'issues-resolution',
                element: <AdminOrderList stage="issues-resolution" />
              },
              {
                path: 'dispensing',
                element: <AdminOrderList stage="dispensing" />
              },
              {
                path: 'delivery',
                element: <AdminOrderList stage="delivery" />
              },
              {
                path: 'completed',
                element: <AdminOrderList stage="completed" />
              },
              {
                path: 'subscriptions',
                element: <AdminSubscriptionList />
              }
            ]
          },
          {
            path: 'services',
            element: withRoleGuard(AdminServices, 'Consultation'),
            children: [
              {
                index: true,
                element: <RedirectTo path="/admin/services/consultation" />
              },
              {
                path: 'consultation',
                element: <AdminConsultationService />
              },
              {
                path: 'wellness',
                element: <AdminWellnessHistory />
              }
            ]
          },
          {
            path: 'services/:id',
            children: [
              {
                path: 'consultation-summary',
                element: <AdminConsultationSummaryPage />,
                children: [
                  {
                    index: true,
                    element: (
                      <RedirectTo path="/admin/services/consultation/:id/consultation-summary/details" />
                    )
                  },
                  {
                    path: 'details',
                    element: <AdminConsultationDetails />
                  },
                  {
                    path: 'activities',
                    element: <AdminConsultationActivities />
                  }
                ]
              }
            ]
          },
          {
            path: 'services/wellness/:type/wellness-details',
            element: <AdminWellnessDetails />
          },
          {
            path: 'customers',
            element: withRoleGuard(AdminCustomer, ['User', 'Customer'])
          },
          {
            path: 'customer',
            children: [
              {
                path: ':id',
                children: [
                  {
                    path: 'orders',
                    element: withRoleGuard(CustomerHistory, ['User', 'Customer'])
                  },
                  {
                    path: 'wallet-history',
                    element: withRoleGuard(WalletHistory, ['User', 'Customer'])
                  },
                  {
                    path: 'affiliate/orders',
                    element: withRoleGuard(CustomerAffiliateOrders, ['User', 'Customer'])
                  }
                ]
              },
              {
                path: 'place-order',
                children: [
                  {
                    index: true,
                    element: withRoleGuard(PlaceOrderCustomer, ['User', 'Customer'])
                  },
                  {
                    path: 'checkout',
                    element: withRoleGuard(CheckoutDeliveryAddress, ['User', 'Customer'])
                  }
                ]
              }
            ]
          },
          {
            path: ':orderNumber/order-summary',
            element: <AdminOrderSummaryPage />,
            children: [
              {
                path: 'details',
                element: withRoleGuard(AdminOrderDetails, 'Orders')
              },
              {
                path: 'activities',
                element: withRoleGuard(AdminOrderActivities, 'Orders')
              }
            ]
          },
          {
            path: 'role',
            children: [
              {
                index: true,
                element: withRoleGuard(AdminRoles, 'Runners')
              }
            ]
          },
          {
            path: 'runners',
            children: [
              {
                index: true,
                element: withRoleGuard(AdminRunners, 'Runners')
              },

              {
                path: 'roles',
                element: withRoleGuard(AdminRoles, 'Runners')
              },
              {
                path: 'role/new',
                element: withRoleGuard(CreateNewRole, 'Runners')
              },
              {
                path: 'role/:id',
                element: withRoleGuard(CreateNewRole, 'Runners')
              },
              {
                path: 'role/:id/permissions',
                element: withRoleGuard(RolePermissions, 'Runners')
              }
            ]
          },
          {
            path: 'inventory',
            element: withRoleGuard(AdminInventory, 'Inventory')
          },
          {
            path: 'coupon',
            children: [
              {
                index: true,
                element: withRoleGuard(AdminCoupon, 'Coupon')
              },
              {
                path: 'history/:couponCode',
                element: withRoleGuard(AdminCouponHistory, 'Coupon')
              }
            ]
          },
          {
            path: 'partners',
            children: [
              {
                index: true,
                element: withRoleGuard(AdminPartners, 'Partners')
              },
              {
                path: ':id/:type-transaction-history',
                element: withRoleGuard(TransactionHistory, 'Partners')
              }
            ]
          },
          {
            path: 'partner',
            children: [
              {
                path: ':id',
                children: [
                  {
                    path: 'orders',
                    element: withRoleGuard(PartnerOrderHistory, 'Partners')
                  },
                  {
                    path: 'members',
                    element: withRoleGuard(PartnerMembers, 'Partners')
                  },
                  {
                    path: 'member/:memberId/orders',
                    element: withRoleGuard(PartnerMemberOrderHistory, 'Partners')
                  },
                  {
                    path: ':memberId/place-order',
                    children: [
                      {
                        index: true,
                        element: withRoleGuard(PlaceOrderPartnerMember, 'Partners')
                      },
                      {
                        path: 'checkout',
                        element: withRoleGuard(PartnerOrderCheckoutAddress, 'Partners')
                      }
                    ]
                  },
                  {
                    path: 'place-order',
                    children: [
                      {
                        index: true,
                        element: withRoleGuard(PlaceOrderPartnerMember, 'Partners')
                      },
                      {
                        path: 'checkout',
                        element: withRoleGuard(PartnerOrderCheckoutAddress, 'Partners')
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            path: 'campaign',
            element: withRoleGuard(AdminCampaign, 'Campaign')
          },
          {
            path: 'pharmacy/:id/history',
            element: withRoleGuard(PharmacyRequestHistory, 'Partners')
          }
        ]
      }
    ]
  }
];

export default routes;
