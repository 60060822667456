import { action, flow, makeObservable, runInAction, observable } from 'mobx';
import { RootStore } from '..';
import {
  costOrder,
  updateOrder,
  dispatchOrder,
  deliverOrder,
  verifyPaymentManually,
  saveComment,
  closeOrderWithReason,
  delOrder,
  addCreditToOrder,
  remitCreditOnOrder,
  getDashboardSummary,
  getTopCategories,
  getOrderByOrderNumber,
  getOrdersInSubscription,
  assignRunnerToOrder,
  assignRunnerToSubscription,
  moveOrderStage,
  assignTagsToOrder,
  assignTagsToSubscription,
  getTags,
  createTags,
  updateProgress,
  getTopProducts,
  adminCloseSubscription,
  getAllOrders,
  editCustomerSource,
  readyToDispatchOrder,
  updateDeliveryFee
} from 'requests/order';
import toast from 'react-hot-toast';
import Alert from 'utils/Alert';
import { parseError } from 'utils';
import { formatMilliSecondsToTime } from 'utils/helpers';
import { chartData } from 'data/dashboard';
import { getUsedCredit } from 'requests/partners';
import { mapOrderStageToRoutes, orderStageRoutes, orderStatusData } from 'constant';

export class AdminOrderStore {
  rootStore: RootStore;
  isScheduleModalOpen = false;
  isOrderSummary = false;
  isSubscriptionSummary = false;
  isAddCommentModalOpen = false;
  isCloseOrderModalOpen = false;
  isCloseSubscriptionModalOpen = false;
  isPaidOnCreditModalOpen = false;
  isAdminReorder = false;
  isEditOrderItem = false;
  isCancelItemModalOpen = false;
  isVerifyCancelPaidItemModalOpen = false;
  isTransactionReceiptModalOpen = false;
  isRefundFormModalOpen = false;
  isRefundCancelledItemModalOpen = false;
  isRefundOptionsModalOpen = false;

  orders: IOrder[] = [];
  allOrders: any[] = [];
  sortCriteria = { key: '', direction: '' };
  sortMenuStates = {} as { [key: string]: boolean };
  recentOrders: IOrder[] = [];
  filterOrderOptions: any = {
    orderType: [],
    orderStatus: [],
    customerType: [],
    customerSource: [],
    comment: [],
    customers: [],
    assignees: [],
    location: []
  };
  searchRunner = '';
  searchSource = '';
  tagOptions = {
    assignModalOpen: false,
    createTagOpen: false
  };

  tagData = [] as TagItem[];
  startDate: Date | any = undefined;
  endDate: Date | any = undefined;
  showCustomRange = false;

  filteredOrders: IAdminOrder[] = [];
  selectedOrderDetails: Nullable<IOrder> = null;
  selectedOrderItem: Nullable<IOrderItem> = null;

  selectedOrder: Nullable<IOrder> = null;
  selectedOrderActor: Nullable<string> = null;
  subscriptionData: Nullable<SubscriptionItem> = null;
  selectedOrderNumbers: Set<string> = new Set();
  tabIndex = 0;
  page = 1;
  totalOrdersCount = 0;
  pageLimit = 10;
  adminOrderStat = {
    totalAwaitingPaymentOrders: 0,
    totalOneTimeOrders: 0,
    totalSubscriptionOrders: 0,
    averageTat: 'N/A',
    totalAwaitingConfirmation: 0
  };
  topCategories = [] as ICategory[];
  topProducts = [] as ITopProduct[];
  dashboardStat: OrderSummaryData = {
    awaitingConfirmation: 0,
    paid: 0,
    totalDelivered: 0,
    revenue: 0,
    totalClosed: 0,
    totalCancelled: 0,
    awaitingPayment: 0,
    totalCustomers: 0,
    topStates: [] as any[],
    customerSources: chartData,
    gmv: 0,
    cogs: 0,
    creditUsed: 0,
    totalDispatched: 0,
    totalAwaitingPaymentOrders: 0,
    totalOneTimeOrders: 0,
    totalSubscriptionOrders: 0,
    totalRevenue: 0
  };
  isLoading = {
    fetchTopCategories: false,
    fetchDashboardStat: false,
    fetchAllOrders: false,
    summary: false,
    costOrder: false,
    updateOrder: false,
    confirmManualPayment: false,
    addCommentToOrder: false,
    readyToDispatchOrder: false,
    dispatchOrder: false,
    deliverOrder: false,
    closeOrder: false,
    deleteOrder: false,
    addCreditToOrder: false,
    remitCreditOnOrder: false,
    fetchOrderByOrderNumber: false,
    fetchOrdersInSubscription: false,
    assignRunnerToOrder: false,
    assignRunnerToSubscription: false,
    moveOrderStage: false,
    editCustomerSource: false,
    addTags: false,
    updateProgress: false,
    topProducts: false,
    assignTagsToSubscription: false,
    getTags: false,
    createTags: false,
    closeSubscription: false,
    filteredOrders: false
  };
  subTotal = 0;
  orderFilterParams = {
    orderStatus: 'All',
    orderType: 'All',
    customerType: 'All',
    startDate: '',
    endDate: ''
  };
  filterUrl = '';
  orderRef = '';
  type = 'order';
  refundType = '';
  refundAmount = 0;
  selectedOrderStage = '';
  orderStagesCount: Record<string, number> = {
    all: 0,
    'customer-success': 0,
    'partner-success': 0,
    'vendor-management': 0,
    'pick-up': 0,
    'issues-resolution': 0,
    dispensing: 0,
    delivery: 0
  };

  stageOrders: Record<string, IAdminOrder[]> = {
    all: [],
    'customer-success': [],
    'partner-success': [],
    'vendor-management': [],
    'pick-up': [],
    'issues-resolution': [],
    dispensing: [],
    delivery: []
  };

  orderStagePages: Record<string, any> = {
    all: {
      page: 1,
      pageLimit: 10
    },
    'customer-success': {
      page: 1,
      pageLimit: 10
    },
    'partner-success': {
      page: 1,
      pageLimit: 10
    },
    'vendor-management': {
      page: 1,
      pageLimit: 10
    },
    'quality-assurance': {
      page: 1,
      pageLimit: 10
    },
    'pick-up': {
      page: 1,
      pageLimit: 10
    },
    'issues-resolution': {
      page: 1,
      pageLimit: 10
    },
    dispensing: {
      page: 1,
      pageLimit: 10
    },
    delivery: {
      page: 1,
      pageLimit: 10
    },
    completed: {
      page: 1,
      pageLimit: 10
    }
  };

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      isScheduleModalOpen: observable,
      tabIndex: observable,
      page: observable,
      totalOrdersCount: observable,
      selectedOrderActor: observable,
      pageLimit: observable,
      orders: observable,
      filteredOrders: observable,
      isOrderSummary: observable,
      selectedOrder: observable,
      isSubscriptionSummary: observable,
      isAddCommentModalOpen: observable,
      isCloseOrderModalOpen: observable,
      isCloseSubscriptionModalOpen: observable,
      isLoading: observable,
      subTotal: observable,
      adminOrderStat: observable,
      dashboardStat: observable,
      isPaidOnCreditModalOpen: observable,
      isEditOrderItem: observable,
      isCancelItemModalOpen: observable,
      isVerifyCancelPaidItemModalOpen: observable,
      selectedOrderNumbers: observable,
      orderFilterParams: observable,
      filterUrl: observable,
      orderRef: observable,
      topCategories: observable,
      filterOrderOptions: observable,
      isTransactionReceiptModalOpen: observable,
      selectedOrderDetails: observable,
      selectedOrderItem: observable,
      subscriptionData: observable,
      topProducts: observable,
      type: observable,
      tagOptions: observable,
      tagData: observable,
      isRefundFormModalOpen: observable,
      isRefundCancelledItemModalOpen: observable,
      isRefundOptionsModalOpen: observable,
      refundType: observable,
      refundAmount: observable,
      searchRunner: observable,
      selectedOrderStage: observable,
      orderStagesCount: observable,
      stageOrders: observable,
      orderStagePages: observable,
      searchSource: observable,
      startDate: observable,
      endDate: observable,
      showCustomRange: observable,
      isAdminReorder: observable,
      sortMenuStates: observable,
      sortCriteria: observable,
      allOrders: observable,

      setOrderFilterParams: action.bound,
      generateUrl: action.bound,
      toggleScheduleModal: action.bound,
      setSelectedOrder: action.bound,
      canReorderItems: action.bound,
      toggleOrderSummary: action.bound,
      toggleSubscriptionSummary: action.bound,
      toggleAddCommentModal: action.bound,
      toggleCloseOrderModal: action.bound,
      updatePharmacy: action.bound,
      adjustCostPrice: action.bound,
      adjustSellingPrice: action.bound,
      reviewSellingPrice: action.bound,
      updateDeliveryFee: action.bound,
      addDiscount: action.bound,
      calculateSubTotal: action.bound,
      calculateOrderTotal: action.bound,
      summaryData: action.bound,
      setTabIndex: action.bound,
      onPageChange: action.bound,
      setOrders: action.bound,
      setRecentOrders: action.bound,
      setPageLimit: action.bound,
      setOrderRef: action.bound,
      togglePaidOnCreditModal: action.bound,
      toggleCancelOrderItemModal: action.bound,
      toggleVerifyCancelPaidItemModal: action.bound,
      toggleOrderSelection: action.bound,
      setAdminReorder: action.bound,
      selectedReOrderAddress: action.bound,
      isOrderSelected: action.bound,
      toggleSelectAllOrders: action.bound,
      clearOrderSelections: action.bound,
      reloadActorOrderPage: action.bound,
      deleteOrderInSelectedOrders: action.bound,
      setFilterOrderOptions: action.bound,
      setAllFilterOrderOptions: action.bound,
      resetFilterOrderOptions: action.bound,
      toggleTransactionReceiptModal: action.bound,
      toggleTagForm: action.bound,
      toggleEditOrderItem: action.bound,
      toggleAssignTagModal: action.bound,
      toggleRefundFormModal: action.bound,
      toggleRefundCancelledItemModal: action.bound,
      toggleRefundOptionsModal: action.bound,
      setSearchRunnerQuery: action.bound,
      setAssignees: action.bound,
      resetAssignees: action.bound,
      addOrderItemQuantity: action.bound,
      removeOrderItem: action.bound,
      subtractOrderItemQuantity: action.bound,
      toggleCloseSubscriptionModal: action.bound,
      setSelectedOrderStage: action.bound,
      setOrderStagePages: action.bound,
      setOrderStageLimit: action.bound,
      resetOrderStagePage: action.bound,
      resetCustomerSource: action.bound,
      resetLocation: action.bound,
      setCustomerSource: action.bound,
      setSearchSource: action.bound,
      setStartDate: action.bound,
      setEndDate: action.bound,
      setShowCustomRange: action.bound,
      setSortMenuState: action.bound,
      setSortCriteria: action.bound,
      setLocation: action.bound,

      fetchAllOrders: flow.bound,
      fetchDashboardSummary: flow.bound,
      fetchTopCategories: flow.bound,
      costOrder: flow.bound,
      updateOrder: flow.bound,
      dispatchOrder: flow.bound,
      deliverOrder: flow.bound,
      confirmManualPayment: flow.bound,
      addCommentToOrder: flow.bound,
      closeOrder: flow.bound,
      closeSubscription: flow.bound,
      deleteOrder: flow.bound,
      addCreditToOrder: flow.bound,
      remitCreditOnOrder: flow.bound,
      fetchOrderByOrderNumber: flow.bound,
      fetchOrdersInSubscription: flow.bound,
      assignRunner: flow.bound,
      assignRunnerToSubscription: flow.bound,
      moveOrderStage: flow.bound,
      addTags: flow.bound,
      assignTagsToSubscription: flow.bound,
      getTags: flow.bound,
      createTags: flow.bound,
      updateProgress: flow.bound,
      fetchTopSellingProducts: flow.bound,
      fetchAdminOrders: flow.bound,
      editCustomerSource: flow.bound,
      readyToDispatchOrder: flow.bound,
    });
    this.rootStore = rootStore;
  }


  setSortCriteria(key: string, direction: string) {
    this.sortCriteria = { key, direction };
  }
  setSortMenuState = (newState: any) => {
    this.sortMenuStates = newState;
  };
  setStartDate(date: Date | null) {
    this.startDate = date;
  }
  setEndDate(date: Date | null) {
    this.endDate = date;
  }

  setShowCustomRange(show: boolean) {
    this.showCustomRange = show;
  }
  setSearchRunnerQuery(query: string) {
    this.searchRunner = query;
  }
  setSearchSource(query: string) {
    this.searchSource = query;
  }
  toggleTagForm = () => {
    this.tagOptions = {
      ...this.tagOptions,
      createTagOpen: !this.tagOptions.createTagOpen
    };
  };
  toggleAssignTagModal = () => {
    this.tagOptions = {
      ...this.tagOptions,
      assignModalOpen: !this.tagOptions.assignModalOpen
    };
  };

  toggleEditOrderItem(setTrue?: boolean) {
    this.refundAmount = 0;
    this.isEditOrderItem =
      setTrue !== null && typeof setTrue === 'boolean' ? setTrue : !this.isEditOrderItem;
  }

  setFilterOrderOptions = (option: string | string[], id: string) => {
    if (this.filterOrderOptions[id]?.includes(option)) {
      this.filterOrderOptions[id] = this.filterOrderOptions[id].filter(
        (item: string) => item !== option
      );
      return;
    }
    this.filterOrderOptions[id].push(option);
  };
  setAllFilterOrderOptions = (option: string, id: string, checked?: boolean) => {
    if (checked) {
      if (!this.filterOrderOptions[id].includes(option)) {
        this.filterOrderOptions[id].push(option);
      }
    } else {
      this.filterOrderOptions[id] = [];
    }
  };
  resetFilterOrderOptions = () => {
    this.filterOrderOptions = {
      orderType: [],
      orderStatus: [],
      customerType: [],
      customerSource: [],
      comment: [],
      customers: [],
      assignees: [],
      location: []
    };
  };
  setOrderFilterParams(field: string, value: string | Date | boolean) {
    this.orderFilterParams = {
      ...this.orderFilterParams,
      [field]: value
    };
    this.generateUrl();
  }
  resetAssignees = () => {
    this.filterOrderOptions.assignees = [];
    this.setAssignees([]);
  };

  setAssignees(assignees: string[]) {
    this.filterOrderOptions.assignees = assignees;
  }
  resetCustomerSource = () => {
    this.filterOrderOptions.customerSource = [];
    this.setCustomerSource([]);
  };

  setCustomerSource(source: string[]) {
    this.filterOrderOptions.customerSource = source;
  }
  resetLocation = () => {
    this.filterOrderOptions.location = [];
    this.setLocation([]);
  };

  setLocation(location: string[]) {
    this.filterOrderOptions.location = location;
  }

  setSelectedOrderStage(stage: string) {
    this.selectedOrderStage = stage;
    this.generateUrl();
  }

  setOrderStagePages(stage: string) {
    return (page: number) => {
      this.orderStagePages[stage].page = page;
    };
  }

  setOrderStageLimit(stage: string) {
    return (limit: number) => {
      this.orderStagePages[stage].pageLimit = limit;
    };
  }

  resetOrderStagePage(stage: string) {
    this.orderStagePages[stage] = {
      page: 1,
      pageLimit: 10
    };
  }

  generateUrl() {
    const searchKey = this.rootStore.AppStore.searchQuery.toLowerCase();
    let url = '';

    const { orderType, customerType, customerSource, comment, customers, assignees, location } =
      this.filterOrderOptions;
    let { orderStatus } = this.filterOrderOptions;
    const { startDate, endDate } = this.orderFilterParams;
    const commentLength = comment.length > 0;
    const customerSourceLength = customerSource.length > 0;
    const customersLength = customers.length > 0;
    let isLogged = '';

    if (orderStatus.includes('isLogged')) {
      isLogged = 'true';
    }

    if (orderStatus.includes('notLogged')) {
      isLogged = 'false';
    }

    if (orderStatus.includes('notLogged') && orderStatus.includes('isLogged')) {
      isLogged = 'all';
    }
    orderStatus = orderStatus.filter((status: string) => !status.includes('isLogged'));
    orderStatus = orderStatus.filter((status: string) => !status.includes('notLogged'));

    if (
      searchKey ||
      orderStatus ||
      orderType ||
      customerType ||
      customerSourceLength ||
      commentLength ||
      customersLength ||
      assignees ||
      startDate ||
      endDate ||
      isLogged ||
      location
    ) {
      url += '?';
      if (searchKey) url += `searchKey=${searchKey}&`;
      if (orderStatus.length > 0) url += `orderStatus=${orderStatus.join(',')}&`;
      if (assignees.length > 0) url += `assignees=${assignees.join(',')}&`;
      if (location.length > 0) url += `location=${location.join(',')}&`;
      if (orderType.length > 0) {
        if (orderType.length === 1) url += `orderType=${orderType[0]}&`;
      }
      if (customerType.length > 0) {
        if (customerType.length === 1) url += `customerType=${customerType[0]}&`;
      }
      if (customerSource.length > 0) url += `customerSource=${customerSource.join(',')}&`;
      if (comment.length > 0) url += `comment=${comment.join(',')}&`;
      if (customers.length > 0) {
        if (customers.length === 1) url += `customers=${customers[0]}&`;
      }
      if (startDate) url += `startDate=${startDate}&`;
      if (endDate) url += `endDate=${endDate}&`;
      if (isLogged) url += `isLogged=${isLogged}&`;

      // Remove trailing '&' if there are no more query parameters
      this.filterUrl = url.slice(0, -1);
    }
  }

  setOrderRef(ref: string) {
    if (ref) this.orderRef = ref;
  }

  toggleOrderSelection(orderNumber: string) {
    if (this.selectedOrderNumbers.has(orderNumber)) {
      this.selectedOrderNumbers.delete(orderNumber);
    } else {
      this.selectedOrderNumbers.add(orderNumber);
    }
  }
  adminReorder(order: IOrder) {
    order.orderItems.forEach((item) => {
      this.rootStore.CartStore.addToCart({
        drugGeneratedId: item._id,
        brandName: item.drugName,
        dosageForm: item.dosageForm,
        quantity: item.quantity,
        price: item.costPrice || 0,
        strength: item.strength,
        manufacturer: item.manufacturer,
        category: item.category,
        unit: item.unit,
        totalPrice: item.amount || 0,
        itemType: item.itemType,
        fileUrl: item.fileUrl
      });
    });
  }

  setAdminReorder(order: IOrder) {
    this.rootStore.CartStore.clearCart();
    if (!order) {
      this.isAdminReorder = false;
      return;
    }
    this.isAdminReorder = true;
    this.adminReorder(order);
    if (!order?.b2b) {
      this.rootStore.UserStore.getUserById(order?.userId as string);
    } else {
      this.rootStore.PartnerStore.setSelectedPartner(order?.userId as string);
      this.rootStore.PartnerStore.getPartnerMembersAdmin(order?.userId as string);
    }
    this.isAdminReorder = false;
  }

  selectedReOrderAddress(address: IDeliveryAddress) {
    if (!address) {
      return;
    }
    const { email, firstName, lastName, phoneNumber, street } = address;
    if (this.rootStore.UserStore.selectedUser === null) {
      return;
    }
    const { friendsAndFamily } = this.rootStore.UserStore.selectedUser as ISelectedUserInfo;
    if (friendsAndFamily && friendsAndFamily.length > 0) {
      const selectedFnf = friendsAndFamily.find(
        (fnf) =>
          fnf.email === email &&
          fnf.firstName === firstName &&
          fnf.lastName === lastName &&
          fnf.phoneNumber === phoneNumber &&
          fnf.street === street
      );
      this.rootStore.UserStore.setSelectedFamilyAndFriends(selectedFnf?._id as string);
    }
  }

  isOrderSelected(orderNumber: string) {
    return this.selectedOrderNumbers.has(orderNumber);
  }
  toggleSelectAllOrders() {
    this.rootStore.UserStore.selectedUserOrders.forEach((order) => {
      if (order.hasCredit && !order.creditPaid) {
        this.selectedOrderNumbers.add(order.orderNumber);
      }
    });
  }

  clearOrderSelections() {
    this.selectedOrderNumbers.clear();
  }

  togglePaidOnCreditModal() {
    this.isPaidOnCreditModalOpen = !this.isPaidOnCreditModalOpen;
  }
  toggleCancelOrderItemModal(itemId?: string) {
    this.isCancelItemModalOpen = !this.isCancelItemModalOpen;
    if (this.selectedOrderDetails) {
      const { orderItems } = this.selectedOrderDetails;
      this.selectedOrderItem = orderItems.find((item) => item._id === itemId) as any;
    }
  }
  toggleVerifyCancelPaidItemModal() {
    this.isVerifyCancelPaidItemModalOpen = !this.isVerifyCancelPaidItemModalOpen;
  }

  toggleScheduleModal() {
    this.isScheduleModalOpen = !this.isScheduleModalOpen;
  }

  toggleCloseOrderModal() {
    this.isCloseOrderModalOpen = !this.isCloseOrderModalOpen;
  }

  toggleCloseSubscriptionModal() {
    this.isCloseSubscriptionModalOpen = !this.isCloseSubscriptionModalOpen;
  }

  toggleRefundFormModal(refundType?: string, reset?: () => void) {
    this.isRefundFormModalOpen = !this.isRefundFormModalOpen;
    if (refundType) this.refundType = refundType;
    if (reset) reset();
  }

  toggleRefundCancelledItemModal(refundType?: string) {
    this.isRefundCancelledItemModalOpen = !this.isRefundCancelledItemModalOpen;
    if (refundType) this.refundType = refundType;
  }

  toggleRefundOptionsModal() {
    this.isRefundOptionsModalOpen = !this.isRefundOptionsModalOpen;
  }

  onPageChange(page: number) {
    this.page = page;
  }

  setOrders(data: any, path?: string) {
    if (data && data.orders) {
      this.orders = data.orders;
      this.filteredOrders = data.orders;
      this.totalOrdersCount = data.totalCount;
      const averageTat = this.calculateAverageTat(data?.stats?.averageTat, data.totalCount);
      this.adminOrderStat = {
        ...this.adminOrderStat,
        ...data.stats,
        averageTat
      };
      if (path) {
        this.orderStagesCount[path as string] = data.totalCount;
        this.stageOrders[path as string] = data.orders;
      }

      if (this.selectedOrder) this.summaryData(this.selectedOrder.orderNumber);
    }
  }

  setRecentOrders(data: any) {
    if (data && data.orders) {
      this.recentOrders = data.orders;
    }
  }

  setPageLimit(limit: number) {
    this.pageLimit = limit;
  }

  setTabIndex(index: number) {
    this.tabIndex = index;
    this.page = 1;
  }
  toggleAddCommentModal() {
    if (this.isAddCommentModalOpen) {
      this.isAddCommentModalOpen = false;
    } else {
      this.isAddCommentModalOpen = true;
    }
  }

  *fetchAdminOrders(stage: string) {
    this.isLoading.filteredOrders = true;
    try {
      const pageLimit = this.orderStagePages?.[stage as string]?.pageLimit ?? 10;
      const page = this.orderStagePages?.[stage as string]?.page ?? 1;

      let url = '';
      if (this.filterUrl) {
        url = `${this.filterUrl}&orderStage=${orderStageRoutes[stage]}`;
      } else {
        url = `?orderStage=${orderStageRoutes[stage]}`;
      }

      if (page && stage) {
        const { data } = (yield getAllOrders(page, pageLimit, url)) as ApiResponse<string>;
        this.setOrders(data, stage);
        return data;
      }
  


    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading.filteredOrders = false;
    }
  }
  *fetchAllOrders(stage: string,) {
    this.isLoading.filteredOrders = false;
    try {
      const pageLimit = this.orderStagePages?.[stage as string]?.pageLimit ?? 0;
   
      let url = '';
      if (this.filterUrl) {
        url = `${this.filterUrl}&orderStage=${orderStageRoutes[stage]}`;
      } else {
        url = `?orderStage=${orderStageRoutes[stage]}`;
      }

      if (stage) {
        const currentPage = 0; 
            const { data } = (yield getAllOrders(currentPage, pageLimit, url)) as ApiResponse<any>;
            this.allOrders= [...data.orders] as any;

        
          this.setOrders(this.allOrders, stage);
          return this?.allOrders;
       
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading.filteredOrders = false;
    }
  }


  *assignRunner(assignee: IAssignee, orderNumber: string) {
    this.isLoading.assignRunnerToOrder = true;
    try {
      const res = (yield assignRunnerToOrder({ assignee }, orderNumber)) as ApiResponse<string>;
      this.fetchOrderByOrderNumber(orderNumber);
      return res;
    } catch (error) {
      toast.error(parseError(error), { position: 'top-right' });
    } finally {
      this.isLoading.assignRunnerToOrder = false;
    }
  }
  *assignRunnerToSubscription(assignee: IAssignee, subscriptionNumber: string) {
    this.isLoading.assignRunnerToSubscription = true;
    try {
      const res = (yield assignRunnerToSubscription(
        { assignee },
        subscriptionNumber
      )) as ApiResponse<string>;
      this.fetchOrdersInSubscription(subscriptionNumber);
      return res;
    } catch (error) {
      toast.error(parseError(error), { position: 'top-right' });
    } finally {
      this.isLoading.assignRunnerToSubscription = false;
    }
  }
  *moveOrderStage(orderNumber: string, stage: string) {
    this.isLoading.moveOrderStage = true;
    const body = {
      orderNumber,
      stage
    };
    try {
      const res = (yield moveOrderStage(body)) as ApiResponse<string>;

      if (res && res?.status) {
        Alert.success({ message: res.message });
        this.fetchAdminOrders(mapOrderStageToRoutes[stage]);
      }
      this.fetchOrderByOrderNumber(orderNumber);
    } catch (error) {
      Alert.error({ message: parseError(error) });
    } finally {
      this.isLoading.moveOrderStage = false;
    }
  }

  *editCustomerSource(orderNumber: string, source: string) {
    this.isLoading.editCustomerSource = true;

    try {
      const res = (yield editCustomerSource(orderNumber, source)) as ApiResponse<string>;

      if (res && res?.status) {
        Alert.success({ message: res.message });
      }
      this.fetchOrderByOrderNumber(orderNumber);
    } catch (error) {
      Alert.error({ message: parseError(error) });
    } finally {
      this.isLoading.editCustomerSource = false;
    }
  }

  *updateProgress(
    orderNumber: string,
    status: string,
    index: number,
    body: { progress: string; id: string }
  ) {
    if (this.selectedOrderDetails && status === 'Pending') {
      const { orderItems } = this.selectedOrderDetails;
      orderItems[index].progress = body.progress;
      return;
    }
    this.isLoading.updateProgress = true;
    try {
      const res = (yield updateProgress(orderNumber, body)) as ApiResponse<string>;
      if (res && res.status) {
        toast.success(res.message, { position: 'top-right' });
        this.fetchOrderByOrderNumber(orderNumber);
        return true;
      }
    } catch (error) {
      Alert.error({ message: parseError(error) });
      return false;
    } finally {
      this.isLoading.updateProgress = false;
    }
  }
  *getTags() {
    this.isLoading.getTags = true;
    try {
      const res = (yield getTags()) as ApiResponse<TagItem>;
      if (res && res?.status) {
        this.tagData = res?.data as any;
      }
    } catch (error) {
      Alert.error({ message: parseError(error) });
    } finally {
      this.isLoading.getTags = false;
    }
  }
  *createTags(body: { label: string; color: string }) {
    this.isLoading.createTags = true;
    try {
      const res = (yield createTags(body)) as ApiResponse<string>;
      if (res && res.status) {
        toast.success(res.message, { position: 'top-right' });
        this.getTags();

        this.toggleTagForm();
        this.toggleAssignTagModal();
        return true;
      }
      return false;
    } catch (error) {
      Alert.error({ message: parseError(error) });
      return false;
    } finally {
      this.isLoading.createTags = false;
    }
  }
  *addTags(orderNumber: string, body: { tag: string }) {
    this.isLoading.addTags = true;
    try {
      const res = (yield assignTagsToOrder(orderNumber, body)) as ApiResponse<string>;
      if (res && res.status) {
        toast.success(res.message, { position: 'top-right' });
        this.fetchOrderByOrderNumber(orderNumber);
        return true;
      }
      return false;
    } catch (error) {
      Alert.error({ message: parseError(error) });
      return false;
    } finally {
      this.isLoading.addTags = false;
    }
  }
  *assignTagsToSubscription(subscriptionNumber: string, body: { tag: string }) {
    this.isLoading.assignTagsToSubscription = true;
    try {
      const res = (yield assignTagsToSubscription(subscriptionNumber, body)) as ApiResponse<string>;
      if (res && res.status) {
        toast.success(res.message, { position: 'top-right' });
        this.fetchOrdersInSubscription(subscriptionNumber);
        return true;
      }
      return false;
    } catch (error) {
      Alert.error({ message: parseError(error) });
      return false;
    } finally {
      this.isLoading.assignTagsToSubscription = false;
    }
  }

  *fetchOrdersInSubscription(subscriptionNumber: string) {
    this.isLoading.fetchOrdersInSubscription = true;
    try {
      const res = (yield getOrdersInSubscription(
        subscriptionNumber
      )) as ApiResponse<SubscriptionItem>;
      if (res && res?.status) {
        this.subscriptionData = res?.data as SubscriptionItem;
      }
    } catch (error) {
      Alert.error({ message: parseError(error) });
    } finally {
      this.isLoading.fetchOrdersInSubscription = false;
    }
  }

  *fetchOrderByOrderNumber(orderNumber: string) {
    this.isLoading.fetchOrderByOrderNumber = true;
    try {
      const res = (yield getOrderByOrderNumber(orderNumber)) as ApiResponse<any>;
      if (res && res?.status) {
        this.selectedOrderDetails = res.data as any;
      }
    } catch (error) {
      Alert.error({ message: parseError(error) });
    } finally {
      this.isLoading.fetchOrderByOrderNumber = false;
    }
  }

  *fetchTopCategories(query = '') {
    this.isLoading.fetchTopCategories = true;
    try {
      const { data } = yield getTopCategories(query);
      if (data) {
        runInAction(() => {
          this.topCategories = data;
        });
      }
    } catch (error) {
      Alert.error({ message: parseError(error) });
    } finally {
      this.isLoading.fetchTopCategories = false;
    }
  }
  *fetchTopSellingProducts(query = '') {
    this.isLoading.topProducts = true;
    try {
      const { data } = yield getTopProducts(query);
      if (data) {
        runInAction(() => {
          this.topProducts = data;
        });
      }
    } catch (error) {
      Alert.error({ message: parseError(error) });
    } finally {
      this.isLoading.topProducts = false;
    }
  }

  *fetchDashboardSummary(query = '') {
    this.isLoading.fetchDashboardStat = true;
    try {
      const { data } = yield getDashboardSummary(query);
      const { data: creditData } = yield getUsedCredit(query);
      if (data) {
        runInAction(() => {
          this.dashboardStat = {
            ...this.dashboardStat,
            awaitingConfirmation: data.awaitingConfirmation,
            paid: data.paid,
            totalDelivered: data.totalDelivered,
            revenue: data.revenue,
            totalClosed: data.totalClosed,
            totalCancelled: data.totalCancelled,
            awaitingPayment: data.awaitingPayment,
            topStates: data.topStates,
            customerSources: data.customerSources,
            totalCustomers: data.totalCustomers,
            cogs: data.cogs,
            gmv: data.gmv,
            creditUsed: creditData.total
          };
        });
      }
    } catch (error) {
      Alert.error({ message: parseError(error) });
    } finally {
      this.isLoading.fetchDashboardStat = false;
    }
  }

  async toggleOrderSummary(orderNumber?: string) {
    if (orderNumber === undefined) {
      this.selectedOrder = null;
      this.isOrderSummary = false;
      return;
    } else {
      await this.summaryData(orderNumber);
    }
  }
  setSelectedOrder(order: IOrder, actor: string) {
    if (!order) {
      this.selectedOrder = null;
      this.isOrderSummary = false;
      this.selectedOrderActor = null;
    } else {
      this.selectedOrder = order;
      this.selectedOrderActor = actor;
      this.isOrderSummary = true;
    }
  }

  canReorderItems = (order: IOrder) => {
    return (order?.orderStatus === orderStatusData.DELIVERED &&
      ((order?.b2b && order?.memberId) || !order?.b2b)) as boolean;
  };
  async summaryData(orderNumber: string) {
    if (orderNumber) {
      let order;
      if (this.selectedOrderActor) {
        order = this.rootStore.UserStore.selectedUserOrders.find(
          (order) => order.orderNumber === orderNumber
        );
      } else {
        order = this.orders.find((order) => order.orderNumber === orderNumber);
      }
      if (order) {
        this.selectedOrder = order;
        await this.calculateSubTotal();
        this.isOrderSummary = true;
      }
    }
  }
  calculateAverageTat = (totalTAT: number, orderCount: number) => {
    if (orderCount === 0 || totalTAT === 0) {
      return '0 seconds';
    }

    const averageDuration = totalTAT / orderCount;
    return formatMilliSecondsToTime(averageDuration);
  };

  updatePharmacy(index: number, pharmacyId: string) {
    if (this.selectedOrderDetails) {
      const { orderItems } = this.selectedOrderDetails;
      orderItems[index].pharmacyId = pharmacyId;
    }
  }

  async adjustCostPrice(index: number, cost: number) {
    if (this.selectedOrderDetails) {
      const { orderItems } = this.selectedOrderDetails;
      const markup = cost * 0.4 + cost;
      orderItems[index].costPrice = cost;
      orderItems[index].unitPrice = markup;
      orderItems[index].amount = markup * orderItems[index].quantity;
      await this.calculateSubTotal();
    }
  }

  async adjustSellingPrice(index: number, cost: number) {
    if (this.selectedOrderDetails) {
      const { orderItems } = this.selectedOrderDetails;
      orderItems[index].unitPrice = cost;
      orderItems[index].amount = cost * orderItems[index].quantity;
      await this.calculateSubTotal();
    }
  }

  reviewSellingPrice(sellingPrice: any, costPrice: any) {
    if (((sellingPrice as unknown as number) < costPrice) as unknown as number) {
      toast.error('Selling price cannot be less than the cost price.');
      return false;
    }
    return true;
  }

  async calculateSubTotal(order?: IOrder) {
    if (order) {
      this.selectedOrderDetails = order as IOrder;
    }
    if (this.selectedOrderDetails) {
      const { orderItems } = this.selectedOrderDetails;
      const subTotal = orderItems.reduce((total, item) => total + item.amount, 0);
      this.subTotal = subTotal;
      const couponValue = this?.selectedOrderDetails?.couponValue ?? 0;
      if (
        this.selectedOrderDetails?.orderStatus === orderStatusData.PENDING ||
        this.selectedOrderDetails?.orderStatus === orderStatusData.AWAITING_PAYMENT
      ) {
        if (this.selectedOrderDetails.discountRule === 'percentage' || this.selectedOrderDetails.couponType === "percentage" && this.subTotal !== 0) {
          this.selectedOrderDetails.serviceCharge =
            subTotal * (100 - couponValue - this.selectedOrderDetails?.discountPercentage) / 100 * 0.02;
        } else if (this.selectedOrderDetails.discountRule === 'fixed' || this.selectedOrderDetails.couponType === "fixed" && this.subTotal !== 0) {
          this.selectedOrderDetails.serviceCharge =
            ((subTotal - couponValue) - this.selectedOrderDetails.discount) * 0.02;
        } else if (this.selectedOrderDetails.discount === 0) {
          this.selectedOrderDetails.serviceCharge = subTotal * 0.02;
        }
      }
    }
  }

  removeOrderItem(id: string) {
    if (this.selectedOrderDetails?.orderItems) {
      const { orderItems } = this.selectedOrderDetails;
      this.refundAmount += orderItems.find((item) => item._id === id)?.amount as number;
      this.selectedOrderDetails.orderItems = orderItems.filter((item) => item._id !== id);
      this.calculateSubTotal();
      this.calculateOrderTotal();
    }
  }
  addOrderItemQuantity(id: string) {
    this.selectedOrderDetails?.orderItems.map((item) => {
      if (item._id === id) {
        (item.quantity = item.quantity + 1),
          (item.amount = (item.unitPrice as number) * item.quantity);
      } else {
        item.quantity;
      }
    });
    this.calculateSubTotal();
    this.calculateOrderTotal();
  }
  subtractOrderItemQuantity(id: string) {
    this.selectedOrderDetails?.orderItems.map((item) => {
      if (item._id === id) {
        if (item.quantity > 1) {
          item.quantity = item.quantity - 1;
          item.amount = (item.unitPrice as number) * item.quantity;
        } else {
          item.quantity = 1;
        }
      }
    });
    this.calculateSubTotal();
    this.calculateOrderTotal();
  }
  calculateOrderTotal() {
    if (this.selectedOrderDetails) {
      const { deliveryFee, sampleCollectionFee, discount } = this.selectedOrderDetails;
      this.selectedOrderDetails.orderTotal =
        (this.subTotal + deliveryFee + sampleCollectionFee || 0) -
        (discount || 0) +
        this.selectedOrderDetails.serviceCharge;
    }
  }
  async updateDeliveryFee(amount: number) {
    if (this.selectedOrderDetails) {
      this.selectedOrderDetails.deliveryFee = amount;
      if (this.selectedOrderDetails.deliveryFee === amount) this.calculateOrderTotal();
    }
  }

  *costOrder() {
    if (this.selectedOrderDetails) {
      const { orderItems, orderNumber } = this.selectedOrderDetails;
      let error = false;
      let priceError = '';
      let pharmError = '';
      // Loop through each order item and check if they have all been costed
      orderItems.forEach((item) => {
        if (!this.reviewSellingPrice(item.unitPrice, item.costPrice)) {
          error = true;
          priceError = 'Please review the prices';
        }
        if (!item.unitPrice && !item.costPrice || item.costPrice === 0 || item.unitPrice === 0) {
          error = true;
          priceError = 'Cost Price and Selling Price are required for all items before costing.';
        }
        if (!item.pharmacyId) {
          error = true;
          pharmError = 'Please select pharmacies for all order items before costing order';
        }
        return;
      });

      if (error && priceError) {
        toast.error(priceError);
        return;
      }

      if (error && pharmError) {
        toast.error(pharmError);
        return;
      }

      // remove serviceCharge from orderTotal and delete service Charge
      const serviceCharge = this.selectedOrderDetails.serviceCharge;
      this.selectedOrderDetails.orderTotal -= serviceCharge;
      this.selectedOrderDetails.serviceCharge = 0;
      if (!error) {
        try {
          this.isLoading.costOrder = true;
          let res = {} as ApiResponse<string>;
          if (
            this.selectedOrderDetails.b2b &&
            this.selectedOrderDetails.orderStatus !== 'Pending'
          ) {
            res = yield updateDeliveryFee(
              orderNumber,
              this.selectedOrderDetails as ICostOrder
            ) as unknown as ApiResponse<string>;
          } else {
            res = (yield costOrder(
              orderNumber,
              this.selectedOrderDetails as ICostOrder
            )) as ApiResponse<string>;
          }
          if (res && res.status) {
            toast.success(res.message, { position: 'top-right' });
            this.fetchOrderByOrderNumber(orderNumber);
            if (this.selectedOrderActor) return this.reloadActorOrderPage();
            return true;
          }
          return false;
        } catch (error) {
          Alert.error({ message: parseError(error) });
          return false;
        } finally {
          this.isLoading.costOrder = false;
        }
      }
    }
  }

  *updateOrder(refundReason: string) {
    if (this.selectedOrderDetails) {
      const { orderItems, orderNumber } = this.selectedOrderDetails;
      const idsAndQuantities: ItemsToUpdate[] = orderItems.map((item) => ({
        id: item._id,
        quantity: item.quantity
      }));
      const itemsForUpdate: IUpdateOrderItem = {
        refundReason: refundReason,
        refundChannel: this.refundType,
        items: idsAndQuantities
      };
      try {
        this.isLoading.updateOrder = true;
        const res = (yield updateOrder(
          orderNumber,
          itemsForUpdate as IUpdateOrderItem
        )) as ApiResponse<IOrder>;
        if (res && res.status) {
          toast.success(res.message, { position: 'top-right' });
          return true;
        }
        return false;
      } catch (error) {
        Alert.error({ message: parseError(error) });
        return false;
      } finally {
        if (refundReason === '' || this.refundType === '') {
          this.toggleEditOrderItem();
        }
        this.isLoading.updateOrder = false;
        this.fetchOrderByOrderNumber(orderNumber);
      }
    }
  }

  *readyToDispatchOrder(
    orderNumber: string,
    options?: {
      isSubscription?: boolean;
      subscriptionNumber: string;
    }
  ) {
    this.isLoading.readyToDispatchOrder = true;
    try {
      const res = (yield readyToDispatchOrder(orderNumber)) as ApiResponse<string>;

      if (res && res.status) {
        if (options && options.isSubscription) {
          this.fetchOrdersInSubscription(options.subscriptionNumber);
        } else {
          this.fetchOrderByOrderNumber(orderNumber);
        }

        toast.success(res.message, { position: 'top-right' });

        if (this.selectedOrderActor) return this.reloadActorOrderPage();
        return true;
      }
      return false;
    } catch (error) {
      Alert.error({ message: parseError(error) });
      return false;
    } finally {
      this.isLoading.readyToDispatchOrder = false;
    }
  }

  *dispatchOrder(
    orderNumber: string,
    dispatch: string,
    options?: {
      isSubscription?: boolean;
      subscriptionNumber: string;
    }
  ) {
    this.isLoading.dispatchOrder = true;
    try {
      const res = (yield dispatchOrder(orderNumber, dispatch)) as ApiResponse<string>;

      if (res && res.status) {
        if (options && options.isSubscription) {
          this.fetchOrdersInSubscription(options.subscriptionNumber);
        } else {
          this.fetchOrderByOrderNumber(orderNumber);
        }

        toast.success(res.message, { position: 'top-right' });

        if (this.selectedOrderActor) return this.reloadActorOrderPage();
        return true;
      }
      return false;
    } catch (error) {
      Alert.error({ message: parseError(error) });
      return false;
    } finally {
      this.isLoading.dispatchOrder = false;
    }
  }

  *deliverOrder(
    orderNumber: string,
    options?: {
      isSubscription?: boolean;
      subscriptionNumber: string;
    }
  ) {
    try {
      this.isLoading.deliverOrder = true;
      const res = (yield deliverOrder(orderNumber)) as ApiResponse<string>;

      if (res && res.status) {
        if (options && options.isSubscription) {
          this.fetchOrdersInSubscription(options.subscriptionNumber);
        } else {
          this.fetchOrderByOrderNumber(orderNumber);
        }

        toast.success(res.message, { position: 'top-right' });

        if (this.selectedOrderActor) return this.reloadActorOrderPage();
        return true;
      }
      return false;
    } catch (error) {
      Alert.error({ message: parseError(error) });
      return false;
    } finally {
      this.isLoading.deliverOrder = false;
    }
  }

  async addDiscount(rule: string, value: number) {
    if (!this.selectedOrderDetails) return;

    const parsedAmount = parseFloat(`${value}`);
    const amount = isNaN(parsedAmount) ? 0 : parsedAmount;

    let discountedPrice = 0;
    let orderTotal = this.selectedOrderDetails.orderTotal;

    // Reset previous discount if it exists
    if (this.selectedOrderDetails.discountRule && this.selectedOrderDetails.discount) {
      orderTotal += this.selectedOrderDetails.discount;

    }
    // Apply the new discount
    if (rule === 'percentage') {

      const newTotal = this.subTotal;
      discountedPrice = newTotal * (amount / 100);
      this.selectedOrderDetails.discountPercentage = amount;
    } else {
      discountedPrice = amount;
    }

    // Update selected order details with new discount information
    this.selectedOrderDetails.discountRule = rule;
    this.selectedOrderDetails.discount = discountedPrice;

    // Apply the discount to the order total
    this.selectedOrderDetails.orderTotal = orderTotal - discountedPrice;

    // Return the updated order details
    return this.selectedOrderDetails;
  }

  *confirmManualPayment(orderNumber: string, orderRef: string, schedule = false) {
    if (orderNumber && orderRef) {
      this.isLoading.confirmManualPayment = true;
      try {
        const res = (yield verifyPaymentManually(
          orderNumber,
          orderRef,
          schedule
        )) as ApiResponse<string>;
        if (res && res.status) {
          toast.success(res.message, { position: 'top-right' });
          this.fetchOrderByOrderNumber(orderNumber);
          if (this.selectedOrderActor) this.reloadActorOrderPage();
          return true;
        }
        return false;
      } catch (error) {
        Alert.error({ message: parseError(error) });
        return false;
      } finally {
        this.isLoading.confirmManualPayment = false;
      }
    }
  }

  *addCommentToOrder(orderNumber: string, message: string, service?: string) {
    if (orderNumber && message && service) {
      this.isLoading.addCommentToOrder = true;
      try {
        const res = (yield saveComment(orderNumber, { message, service } as {
          message: string;
          service: string;
        })) as ApiResponse<string>;
        if (res && res.status) {
          toast.success(res.message, { position: 'top-right' });

          return true;
        }
        return false;
      } catch (error) {
        Alert.error({ message: parseError(error) });
        return false;
      } finally {
        this.isLoading.addCommentToOrder = false;
      }
    }
  }

  *closeOrder(message: string, orderNumber: string) {
    this.isLoading.closeOrder = true;
    try {
      if (message) {
        const res = (yield closeOrderWithReason(orderNumber, message)) as ApiResponse<string>;
        if (res && res.status) {
          toast.success(res.message, { position: 'top-right' });
          this.fetchOrderByOrderNumber(orderNumber);
          return true;
        }
        return false;
      }
    } catch (error) {
      Alert.error({ message: parseError(error) });
      return false;
    } finally {
      this.isLoading.closeOrder = false;
    }
  }

  *closeSubscription(message: string, subscriptionNumber: string) {
    this.isLoading.closeSubscription = true;
    try {
      if (message) {
        const res = (yield adminCloseSubscription(
          subscriptionNumber,
          message
        )) as ApiResponse<string>;
        if (res && res.status) {
          toast.success(res.message, { position: 'top-right' });
          this.fetchOrdersInSubscription(subscriptionNumber);
          return true;
        }
        return false;
      }
    } catch (error) {
      Alert.error({ message: parseError(error) });
      return false;
    } finally {
      this.isLoading.closeSubscription = false;
    }
  }

  *deleteOrder(orderNumber: string) {
    if (this.selectedOrderDetails && orderNumber) {
      this.isLoading.deleteOrder = true;
      try {
        const res = (yield delOrder(orderNumber)) as ApiResponse<string>;
        if (res && res.status) {
          toast.success(res.message, { position: 'top-right' });
          return true;
        }
        return false;
      } catch (error) {
        Alert.error({ message: parseError(error) });
        return false;
      } finally {
        this.isLoading.deleteOrder = false;
      }
    }
  }
  toggleSubscriptionSummary() {
    if (this.isSubscriptionSummary) {
      this.isSubscriptionSummary = false;
    } else {
      this.isSubscriptionSummary = true;
    }
  }

  *addCreditToOrder(orderNumber: string) {
    if (this.selectedOrderDetails && orderNumber) {
      this.isLoading.addCreditToOrder = true;
      try {
        const res = (yield addCreditToOrder(orderNumber)) as ApiResponse<string>;
        if (res && res.status) {
          this.fetchOrderByOrderNumber(orderNumber);
          toast.success(res.message, { position: 'top-right' });
          return true;
        }
        return false;
      } catch (error) {
        Alert.error({ message: parseError(error) });
        return false;
      } finally {
        this.isLoading.addCreditToOrder = false;
        this.togglePaidOnCreditModal();
      }
    }
  }

  *remitCreditOnOrder(orderNumbers: string[]) {
    this.isLoading.remitCreditOnOrder = true;
    try {
      const res = (yield remitCreditOnOrder(orderNumbers)) as ApiResponse<string>;
      if (res && res.status) {
        this.fetchOrderByOrderNumber(orderNumbers[0]);
        toast.success(res.message, { position: 'top-right' });
        if (this.selectedOrderActor) {
          yield this.rootStore.UserStore.getUserOrdersById(this.selectedOrderActor, 0, 0);
          yield this.rootStore.PartnerStore.getSinglePartner(this.selectedOrderActor);
        }
        // unset each orderNumber
        for (const orderNumber of orderNumbers) {
          this.deleteOrderInSelectedOrders(orderNumber);
        }
        return true;
      }
      return false;
    } catch (error) {
      console.log(error);
      Alert.error({ message: parseError(error) });
      return false;
    } finally {
      this.togglePaidOnCreditModal();
      this.isLoading.remitCreditOnOrder = false;
    }
  }
  deleteOrderInSelectedOrders(orderNumber: string) {
    this.selectedOrderNumbers.delete(orderNumber);
  }
  async reloadActorOrderPage() {
    if (this.selectedOrderActor && this.selectedOrderDetails) {
      this.rootStore.UserStore.getUserOrdersById(this.selectedOrderActor, 0, 0);
      await this.summaryData(this.selectedOrderDetails.orderNumber);
    }
  }

  toggleTransactionReceiptModal(orderNumber: string) {
    this.selectedOrder = this.orders.find((order) => order.orderNumber === orderNumber) as IOrder;
    this.isTransactionReceiptModalOpen = !this.isTransactionReceiptModalOpen;
  }
}
