export const cities: { name: string; value: string; price: number }[] = [
  { name: 'Abule Egba (Agbado Ijaye Road)', value: 'Abule Egba (Agbado Ijaye Road)', price: 6500 },
  { name: 'Abule Egba (Ajasa Command Rd)', value: 'Abule Egba (Ajasa Command Rd)', price: 7000 },
  { name: 'Abule Egba (Ajegunle)', value: 'Abule Egba (Ajegunle)', price: 7000 },
  { name: 'Abule Egba (Alakuko)', value: 'Abule Egba (Alakuko)', price: 7000 },
  { name: 'Abule Egba (Ekoro road)', value: 'Abule Egba (Ekoro road)', price: 6500 },
  { name: 'Abule Egba (Meiran Road)', value: 'Abule Egba (Meiran Road)', price: 7000 },
  { name: 'Abule Egba (New Oko Oba)', value: 'Abule Egba (New Oko Oba)', price: 5500 },
  { name: 'Abule Egba (Old Otta Road)', value: 'Abule Egba (Old Otta Road)', price: 7000 },
  { name: 'Agege (Ajuwon Akute Road)', value: 'Agege (Ajuwon Akute Road)', price: 6500 },
  { name: 'Agege (Dopemu)', value: 'Agege (Dopemu)', price: 6000 },
  { name: 'Agege (Iju Road)', value: 'Agege (Iju Road)', price: 6500 },
  { name: 'Agege (Old Abeokuta Road)', value: 'Agege (Old Abeokuta Road)', price: 6000 },
  { name: 'Agege (Old Otta Road)', value: 'Agege (Old Otta Road)', price: 6000 },
  { name: 'Agege (Orile Agege)', value: 'Agege (Orile Agege)', price: 5500 },
  { name: 'Agiliti', value: 'Agiliti', price: 5000 },
  { name: 'Ajao Estate', value: 'Ajao Estate', price: 5500 },
  { name: 'Amuwo Odofin', value: 'Amuwo Odofin', price: 5000 },
  { name: 'Anthony Village', value: 'Anthony Village', price: 4800 },
  { name: 'Apapa (Ajegunle)', value: 'Apapa (Ajegunle)', price: 3600 },
  { name: 'Apapa (Amukoko)', value: 'Apapa (Amukoko)', price: 3500 },
  { name: 'Apapa (GRA)', value: 'Apapa (GRA)', price: 3500 },
  { name: 'Apapa (Kiri kiri)', value: 'Apapa (Kiri kiri)', price: 4200 },
  { name: 'Apapa (Olodi)', value: 'Apapa (Olodi)', price: 4000 },
  { name: 'Apapa (Suru Alaba)', value: 'Apapa (Suru Alaba)', price: 4200 },
  { name: 'Apapa (Tincan)', value: 'Apapa (Tincan)', price: 4000 },
  { name: 'Apapa (Warf Rd)', value: 'Apapa (Warf Rd)', price: 3500 },
  { name: 'Awoyaya', value: 'Awoyaya', price: 4500 },
  { name: 'Awoyaya-Container bustop', value: 'Awoyaya-Container bustop', price: 4200 },
  { name: 'Awoyaya-Eko Akete Estate', value: 'Awoyaya-Eko Akete Estate', price: 4000 },
  { name: 'Awoyaya-Eputu', value: 'Awoyaya-Eputu', price: 4800 },
  { name: 'Awoyaya-Gbetu Iwerekun Road', value: 'Awoyaya-Gbetu Iwerekun Road', price: 5000 },
  { name: 'Awoyaya-Idowu Eletu', value: 'Awoyaya-Idowu Eletu', price: 4300 },
  { name: 'Awoyaya-Mayfair Gardens', value: 'Awoyaya-Mayfair Gardens', price: 4400 },
  { name: 'Awoyaya-Ogunlana Busstop', value: 'Awoyaya-Ogunlana Busstop', price: 4500 },
  { name: 'Awoyaya-Ologunfe', value: 'Awoyaya-Ologunfe', price: 4500 },
  { name: 'Awoyaya-Oribanwa', value: 'Awoyaya-Oribanwa', price: 5000 },
  { name: 'Badagry', value: 'Badagry', price: 8000 },
  { name: 'Berger', value: 'Berger', price: 5500 },
  { name: 'Bogije (Ajah)', value: 'Bogije (Ajah)', price: 5000 },
  { name: 'Coker', value: 'Coker', price: 3700 },
  { name: 'Doyin', value: 'Doyin', price: 3200 },
  { name: 'Ejigbo-Bucknor', value: 'Ejigbo-Bucknor', price: 4800 },
  { name: 'Ejigbo-Ile Epo', value: 'Ejigbo-Ile Epo', price: 5000 },
  { name: 'Ejigbo-Isheri Osun', value: 'Ejigbo-Isheri Osun', price: 4800 },
  { name: 'Ejigbo-Jakande Wood Market', value: 'Ejigbo-Jakande Wood Market', price: 4500 },
  { name: 'Ejigbo-NNPC Road', value: 'Ejigbo-NNPC Road', price: 5900 },
  { name: 'Ejigbo-Oke-Afa', value: 'Ejigbo-Oke-Afa', price: 5200 },
  { name: 'Ejigbo-Pipeline', value: 'Ejigbo-Pipeline', price: 5600 },
  { name: 'Ejigbo-Powerline', value: 'Ejigbo-Powerline', price: 5400 },
  { name: 'Elemoro', value: 'Elemoro', price: 5100 },
  { name: 'Epe', value: 'Epe', price: 8000 },
  { name: 'Fagba (Iju Road)', value: 'Fagba (Iju Road)', price: 6000 },
  { name: 'Festac (nd Avenue)', value: 'Festac (nd Avenue)', price: 4500 },
  { name: 'Festac (rd Avenue)', value: 'Festac (rd Avenue)', price: 4500 },
  { name: 'Festac (st Avenue)', value: 'Festac (st Avenue)', price: 4800 },
  { name: 'Festac (th Avenue)', value: 'Festac (th Avenue)', price: 4800 },
  { name: 'Festac (th Avenue)', value: 'Festac (th Avenue)', price: 5000 },
  { name: 'Gbagada- Ifako', value: 'Gbagada- Ifako', price: 3500 },
  { name: 'Gbagada-Abule Okuta', value: 'Gbagada-Abule Okuta', price: 3500 },
  { name: 'Gbagada-Araromi', value: 'Gbagada-Araromi', price: 3500 },
  { name: 'Gbagada-Deeper Life', value: 'Gbagada-Deeper Life', price: 3500 },
  { name: 'Gbagada-Diya', value: 'Gbagada-Diya', price: 3500 },
  { name: 'Gbagada-Expressway', value: 'Gbagada-Expressway', price: 3500 },
  { name: 'Gbagada-Hospital', value: 'Gbagada-Hospital', price: 3500 },
  { name: 'Gbagada-L&K', value: 'Gbagada-L&K', price: 3500 },
  { name: 'Gbagada-New Garage', value: 'Gbagada-New Garage', price: 3500 },
  { name: 'Gbagada-Olopomeji', value: 'Gbagada-Olopomeji', price: 3500 },
  { name: 'Gbagada-Pedro', value: 'Gbagada-Pedro', price: 3500 },
  { name: 'Gbagada-Sawmill', value: 'Gbagada-Sawmill', price: 3500 },
  { name: 'Gbagada-Sholuyi', value: 'Gbagada-Sholuyi', price: 3500 },
  { name: 'Ibeju-Lekki Aiyeteju', value: 'Ibeju-Lekki Aiyeteju', price: 7400 },
  { name: 'Ibeju-Lekki Akodo', value: 'Ibeju-Lekki Akodo', price: 7900 },
  { name: 'Ibeju-Lekki Amen Estate', value: 'Ibeju-Lekki Amen Estate', price: 6700 },
  { name: 'Ibeju-Lekki Dangote fertilizer', value: 'Ibeju-Lekki Dangote fertilizer', price: 7700 },
  { name: 'Ibeju-Lekki Dangote Refinery', value: 'Ibeju-Lekki Dangote Refinery', price: 9100 },
  { name: 'Ibeju-Lekki Dano Milk', value: 'Ibeju-Lekki Dano Milk', price: 9100 },
  { name: 'Ibeju-Lekki Eleko Junction', value: 'Ibeju-Lekki Eleko Junction', price: 6700 },
  { name: 'Ibeju-Lekki Igando', value: 'Ibeju-Lekki Igando', price: 6700 },
  { name: 'Ibeju-Lekki Magbon', value: 'Ibeju-Lekki Magbon', price: 7400 },
  { name: 'Ibeju-Lekki Onosa', value: 'Ibeju-Lekki Onosa', price: 7000 },
  { name: 'Ibeju-Lekki Orimedu', value: 'Ibeju-Lekki Orimedu', price: 7600 },
  {
    name: 'Ibeju-Lekki Pan African University',
    value: 'Ibeju-Lekki Pan African University',
    price: 7400
  },
  { name: 'Ibeju-Lekki Shapati', value: 'Ibeju-Lekki Shapati', price: 5600 },
  { name: 'Idimu', value: 'Idimu', price: 7300 },
  { name: 'Igando', value: 'Igando', price: 6200 },
  { name: 'Ijanikin', value: 'Ijanikin', price: 6700 },
  { name: 'Ijegun Ikotun', value: 'Ijegun Ikotun', price: 5800 },
  { name: 'Ijegun-Obadore Road', value: 'Ijegun-Obadore Road', price: 6800 },
  { name: 'Ijora', value: 'Ijora', price: 3200 },
  { name: 'Ikeja (ADENIYI JONES)', value: 'Ikeja (ADENIYI JONES)', price: 3500 },
  { name: 'Ikeja (ALAUSA)', value: 'Ikeja (ALAUSA)', price: 3500 },
  { name: 'Ikeja (ALLEN AVENUE)', value: 'Ikeja (ALLEN AVENUE)', price: 3500 },
  { name: 'Ikeja (computer village)', value: 'Ikeja (computer village)', price: 3500 },
  { name: 'Ikeja (GRA)', value: 'Ikeja (GRA)', price: 3500 },
  { name: 'Ikeja (M M Airport)', value: 'Ikeja (M M Airport)', price: 5400 },
  { name: 'Ikeja (MANGORO)', value: 'Ikeja (MANGORO)', price: 5400 },
  { name: 'Ikeja (OBA-AKRAN)', value: 'Ikeja (OBA-AKRAN)', price: 5000 },
  { name: 'Ikeja (OPEBI)', value: 'Ikeja (OPEBI)', price: 4900 },
  { name: 'Ikorodu (Adamo)', value: 'Ikorodu (Adamo)', price: 8100 },
  { name: 'Ikorodu (Agbede)', value: 'Ikorodu (Agbede)', price: 6700 },
  { name: 'Ikorodu (Agbowa)', value: 'Ikorodu (Agbowa)', price: 6900 },
  { name: 'Ikorodu (Agric)', value: 'Ikorodu (Agric)', price: 6400 },
  { name: 'Ikorodu (Bayeku)', value: 'Ikorodu (Bayeku)', price: 7600 },
  { name: 'Ikorodu (Eyita)', value: 'Ikorodu (Eyita)', price: 6900 },
  { name: 'Ikorodu (Gberigbe)', value: 'Ikorodu (Gberigbe)', price: 6700 },
  { name: 'Ikorodu (Ijede)', value: 'Ikorodu (Ijede)', price: 8300 },
  { name: 'Ikorodu (Imota)', value: 'Ikorodu (Imota)', price: 10000 },
  { name: 'Ikorodu (Ita oluwo)', value: 'Ikorodu (Ita oluwo)', price: 7800 },
  { name: 'Ikorodu (Itamaga)', value: 'Ikorodu (Itamaga)', price: 7200 },
  { name: 'Ikorodu (Offin)', value: 'Ikorodu (Offin)', price: 7500 },
  { name: 'Ikorodu (Owode-Ibese)', value: 'Ikorodu (Owode-Ibese)', price: 6500 },
  { name: 'Ikorodu (RADIO)', value: 'Ikorodu (RADIO)', price: 8200 },
  { name: 'Ikorodu Road-Ajegunle', value: 'Ikorodu Road-Ajegunle', price: 5000 },
  { name: 'Ikorodu Road-Irawo', value: 'Ikorodu Road-Irawo', price: 5400 },
  { name: 'Ikorodu Road-Owode Onirin', value: 'Ikorodu Road-Owode Onirin', price: 6300 },
  {
    name: 'Ikorodu- Imota Caleb University',
    value: 'Ikorodu- Imota Caleb University',
    price: 10000
  },
  { name: 'Ikorodu-Agufoye', value: 'Ikorodu-Agufoye', price: 9300 },
  { name: 'Ikorodu-Benson', value: 'Ikorodu-Benson', price: 7100 },
  { name: 'Ikorodu-Garage', value: 'Ikorodu-Garage', price: 7100 },
  { name: 'Ikorodu-Odokekere', value: 'Ikorodu-Odokekere', price: 8600 },
  { name: 'Ikorodu-Odonla', value: 'Ikorodu-Odonla', price: 6700 },
  { name: 'Ikorodu-Ogijo', value: 'Ikorodu-Ogijo', price: 6700 },
  { name: 'Ikorodu(Elepe)', value: 'Ikorodu(Elepe)', price: 6700 },
  { name: 'Ikorodu(Laspotech)', value: 'Ikorodu(Laspotech)', price: 7150 },
  { name: 'Ikorodu(Ogolonto)', value: 'Ikorodu(Ogolonto)', price: 6000 },
  { name: 'Ikorodu(Sabo)', value: 'Ikorodu(Sabo)', price: 6400 },
  { name: 'Ikota', value: 'Ikota', price: 1550 },
  { name: 'Ikotun', value: 'Ikotun', price: 7000 },
  { name: 'Ikoyi (Awolowo Road)', value: 'Ikoyi (Awolowo Road)', price: 2000 },
  { name: 'Ikoyi (Bourdillon)', value: 'Ikoyi (Bourdillon)', price: 2000 },
  { name: 'Ikoyi (Dolphin)', value: 'Ikoyi (Dolphin)', price: 2000 },
  { name: 'Ikoyi (Glover road)', value: 'Ikoyi (Glover road)', price: 2000 },
  { name: 'Ikoyi (Keffi)', value: 'Ikoyi (Keffi)', price: 2050 },

  { name: 'Ikoyi (Kings way road)', value: 'Ikoyi (Kings way road)', price: 1980 },
  { name: 'Ikoyi (Obalende)', value: 'Ikoyi (Obalende)', price: 2200 },
  { name: 'Ikoyi (Queens Drive)', value: 'Ikoyi (Queens Drive)', price: 2500 },
  { name: 'Ikoyi Mtn (Pickup Station)', value: 'Ikoyi Mtn (Pickup Station)', price: 2000 },
  { name: 'Ikoyi-Banana Island', value: 'Ikoyi-Banana Island', price: 2000 },
  { name: 'Ilaje (Bariga)', value: 'Ilaje (Bariga)', price: 4300 },
  { name: 'Ilupeju (Lagos)', value: 'Ilupeju (Lagos)', price: 4700 },
  { name: 'Isheri Ikotun', value: 'Isheri Ikotun', price: 6000 },
  { name: 'Isheri Magodo', value: 'Isheri Magodo', price: 5700 },
  { name: 'Isolo', value: 'Isolo', price: 5800 },
  { name: 'Iyana Ejigbo', value: 'Iyana Ejigbo', price: 5500 },
  { name: 'Iyana Iba', value: 'Iyana Iba', price: 6450 },
  { name: 'Iyana Ipaja (Abesan)', value: 'Iyana Ipaja (Abesan)', price: 5800 },
  { name: 'Iyana Ipaja (Aboru)', value: 'Iyana Ipaja (Aboru)', price: 6600 },
  { name: 'Iyana Ipaja (Ayobo Road)', value: 'Iyana Ipaja (Ayobo Road)', price: 6300 },
  { name: 'Iyana Ipaja (Command Road)', value: 'Iyana Ipaja (Command Road)', price: 6550 },
  { name: 'Iyana Ipaja (Egbeda)', value: 'Iyana Ipaja (Egbeda)', price: 6100 },
  { name: 'Iyana Ipaja (Ikola Road)', value: 'Iyana Ipaja (Ikola Road)', price: 6500 },
  { name: 'Iyana Ipaja (Iyana Ipaja Road)', value: 'Iyana Ipaja (Iyana Ipaja Road)', price: 6300 },
  { name: 'Iyana Ipaja (Shasha)', value: 'Iyana Ipaja (Shasha)', price: 7000 },
  { name: 'Jakande (Lekki)', value: 'Jakande (Lekki)', price: 1550 },
  { name: 'Jakande (Isolo)', value: 'Jakande (Isolo)', price: 5600 },
  { name: 'Jumia-Experience-Center', value: 'Jumia-Experience-Center', price: 1550 },
  { name: 'Ketu-Agboyi', value: 'Ketu-Agboyi', price: 4700 },
  { name: 'Ketu-Alapere', value: 'Ketu-Alapere', price: 4700 },
  { name: 'Ketu-Cmd road', value: 'Ketu-Cmd road', price: 5000 },
  { name: 'Ketu-Demurin', value: 'Ketu-Demurin', price: 4800 },
  { name: 'Ketu-Ikosi Road', value: 'Ketu-Ikosi Road', price: 4800 },
  { name: 'Ketu-Ile Ile', value: 'Ketu-Ile Ile', price: 5000 },
  { name: 'Ketu-Iyana School', value: 'Ketu-Iyana School', price: 4650 },
  { name: 'Ketu-Tipper Garage', value: 'Ketu-Tipper Garage', price: 4850 },
  { name: 'Lagos Island (Adeniji)', value: 'Lagos Island (Adeniji)', price: 2500 },
  { name: 'Lagos Island (Marina)', value: 'Lagos Island (Marina)', price: 3200 },
  { name: 'Lagos Island (Onikan)', value: 'Lagos Island (Onikan)', price: 2200 },
  { name: 'Lagos Island (Sura)', value: 'Lagos Island (Sura)', price: 2300 },
  { name: 'Lagos Island (TBS)', value: 'Lagos Island (TBS)', price: 2200 },
  { name: 'Lakowe', value: 'Lakowe', price: 5700 },
  { name: 'Lakowe-Adeba Road', value: 'Lakowe-Adeba Road', price: 4800 },
  { name: 'Lakowe-Golf', value: 'Lakowe-Golf', price: 5100 },
  { name: 'Lakowe-Kajola', value: 'Lakowe-Kajola', price: 5800 },
  { name: 'Lakowe-School Gate', value: 'Lakowe-School Gate', price: 5500 },
  { name: 'Lekki (Bishop Durosimi)', value: 'Lekki (Bishop Durosimi)', price: 1550 },
  { name: 'Lekki (F.T Kuboye street)', value: 'Lekki (F.T Kuboye street)', price: 1550 },
  { name: 'Lekki (Omorinre Johnson)', value: 'Lekki (Omorinre Johnson)', price: 1550 },
  { name: 'Lekki - Vgc', value: 'Lekki - Vgc', price: 2200 },
  { name: 'Lekki Phase (Admiralty Road)', value: 'Lekki Phase (Admiralty Road)', price: 1550 },
  { name: 'Lekki Phase (Admiralty way)', value: 'Lekki Phase (Admiralty way)', price: 1550 },
  { name: 'Lekki Phase (Fola Osibo)', value: 'Lekki Phase (Fola Osibo)', price: 1550 },
  { name: 'Lekki - Agungi', value: 'Lekki - Agungi', price: 1600 },
  { name: 'Lekki- Ajah (Abijo)', value: 'Lekki- Ajah (Abijo)', price: 3600 },
  { name: 'Lekki-Ajah (Addo Road)', value: 'Lekki-Ajah (Addo Road)', price: 2500 },
  { name: 'Lekki-Ajah (Badore)', value: 'Lekki-Ajah (Badore)', price: 3700 },
  { name: 'Lekki-Ajah (Ilaje)', value: 'Lekki-Ajah (Ilaje)', price: 2300 },
  { name: 'Lekki-Ajah (Ilasan)', value: 'Lekki-Ajah (Ilasan)', price: 1550 },
  { name: 'Lekki-Ajah (Jakande)', value: 'Lekki-Ajah (Jakande)', price: 1550 },
  { name: 'Lekki-Ajah (Sangotedo)', value: 'Lekki-Ajah (Sangotedo)', price: 3500 },
  { name: 'Lekki-Awoyaya', value: 'Lekki-Awoyaya', price: 4400 },
  { name: 'Lekki-Chisco', value: 'Lekki-Chisco', price: 1550 },
  { name: 'Lekki-Elf', value: 'Lekki-Elf', price: 1550 },
  { name: 'Lekki-Igboefon', value: 'Lekki-Igboefon', price: 1550 },
  { name: 'Lekki-Ikate Elegushi', value: 'Lekki-Ikate Elegushi', price: 1550 },
  { name: 'Lekki-Jakande (Kazeem Eletu)', value: 'Lekki-Jakande (Kazeem Eletu)', price: 1550 },
  { name: 'Lekki-Maruwa', value: 'Lekki-Maruwa', price: 1550 },
  { name: 'Lekki-Oniru Estate', value: 'Lekki-Oniru Estate', price: 1550 },
  { name: 'Lekki-Osapa London', value: 'Lekki-Osapa London', price: 1550 },
  { name: 'Magboro', value: 'Magboro', price: 7950 },
  { name: 'Magodo', value: 'Magodo', price: 4000 },
  { name: 'Maryland (Mende)', value: 'Maryland (Mende)', price: 3500 },
  { name: 'Maryland (Onigbongbo)', value: 'Maryland (Onigbongbo)', price: 3500 },
  { name: 'Mebanmu', value: 'Mebanmu', price: 3400 },
  { name: 'Mile 2', value: 'Mile 2', price: 4400 },
  { name: 'Mile 12', value: 'Mile 12', price: 4500 },
  { name: 'Mile -Ajelogo', value: 'Mile -Ajelogo', price: 4500 },
  { name: 'Mile -Agboyi Ketu', value: 'Mile -Agboyi Ketu', price: 4500 },
  { name: 'Mile -Doyin Omololu', value: 'Mile -Doyin Omololu', price: 4000 },
  { name: 'Mile -Orishigun', value: 'Mile -Orishigun', price: 4000 },
  { name: 'Mushin -Palm Avenue', value: 'Mushin -Palm Avenue', price: 3500 },
  { name: 'Mushin-Agege Motor Road', value: 'Mushin-Agege Motor Road', price: 3500 },

  { name: 'Mushin-Daleko Market', value: 'Mushin-Daleko Market', price: 4000 },
  { name: 'Mushin-Fatai Atere', value: 'Mushin-Fatai Atere', price: 4500 },
  { name: 'Mushin-Idi Oro', value: 'Mushin-Idi Oro', price: 3500 },
  { name: 'Mushin-Idi-Araba', value: 'Mushin-Idi-Araba', price: 3500 },
  { name: 'Mushin-Ilasamaja Road', value: 'Mushin-Ilasamaja Road', price: 3500 },
  { name: 'Mushin-Isolo Road', value: 'Mushin-Isolo Road', price: 3500 },
  { name: 'Mushin-Ladipo Road', value: 'Mushin-Ladipo Road', price: 3500 },
  { name: 'Mushin-Mushin Market', value: 'Mushin-Mushin Market', price: 3500 },
  { name: 'Mushin-Olateju', value: 'Mushin-Olateju', price: 3500 },
  { name: 'Mushin-Papa Ajao', value: 'Mushin-Papa Ajao', price: 3500 },
  { name: 'Odongunyan', value: 'Odongunyan', price: 7600 },
  { name: 'Ogba- Akilo Road', value: 'Ogba- Akilo Road', price: 4500 },
  { name: 'Ogba- College Road', value: 'Ogba- College Road', price: 5000 },
  { name: 'Ogba- Lateef Jakande Road', value: 'Ogba- Lateef Jakande Road', price: 4500 },
  { name: 'Ogba-Acme Road', value: 'Ogba-Acme Road', price: 4500 },
  { name: 'Ogba-Aguda', value: 'Ogba-Aguda', price: 5000 },
  { name: 'Ogba-County', value: 'Ogba-County', price: 5000 },
  { name: 'Ogba-Ifako-Idiagbon', value: 'Ogba-Ifako-Idiagbon', price: 5000 },
  { name: 'Ogba-Ifako-Orimolade', value: 'Ogba-Ifako-Orimolade', price: 5000 },
  { name: 'Ogba-Isheri Road', value: 'Ogba-Isheri Road', price: 4500 },
  { name: 'Ogba-Obawole', value: 'Ogba-Obawole', price: 4500 },
  { name: 'Ogba-Ojodu', value: 'Ogba-Ojodu', price: 4500 },
  { name: 'Ogba-Oke Ira', value: 'Ogba-Oke Ira', price: 4500 },
  { name: 'Ogba-Oke Ira nd Juction', value: 'Ogba-Oke Ira nd Juction', price: 6000 },
  { name: 'Ogba-Surulere Ind Rd', value: 'Ogba-Surulere Ind Rd', price: 6000 },
  { name: 'Ogba-Wemco Road', value: 'Ogba-Wemco Road', price: 5900 },
  { name: 'Ogudu', value: 'Ogudu', price: 4600 },
  { name: 'Ojo Shibiri', value: 'Ojo Shibiri', price: 7000 },
  { name: 'Ojo-Abule Oshun', value: 'Ojo-Abule Oshun', price: 5600 },
  { name: 'Ojo-Adaloko', value: 'Ojo-Adaloko', price: 6900 },
  { name: 'Ojo-Agric', value: 'Ojo-Agric', price: 6100 },
  { name: 'Ojo-Ajangbadi', value: 'Ojo-Ajangbadi', price: 7500 },
  { name: 'Ojo-Alaba International', value: 'Ojo-Alaba International', price: 6400 },
  { name: 'Ojo-Alaba Rago', value: 'Ojo-Alaba Rago', price: 6600 },
  { name: 'Ojo-Alaba Suru', value: 'Ojo-Alaba Suru', price: 4700 },
  { name: 'Ojo-Alakija', value: 'Ojo-Alakija', price: 4800 },
  { name: 'Ojo-Cassidy', value: 'Ojo-Cassidy', price: 7000 },
  { name: 'Ojo-Ijegun', value: 'Ojo-Ijegun', price: 6500 },
  { name: 'Ojo-Ilogbo', value: 'Ojo-Ilogbo', price: 7000 },
  { name: 'Ojo-Ojo Barracks', value: 'Ojo-Ojo Barracks', price: 5500 },
  { name: 'Ojo-Okokomaiko', value: 'Ojo-Okokomaiko', price: 7400 },
  { name: 'Ojo-Old Ojo road', value: 'Ojo-Old Ojo road', price: 4500 },
  { name: 'Ojo-Onireke', value: 'Ojo-Onireke', price: 5700 },
  { name: 'Ojo-Ppl', value: 'Ojo-Ppl', price: 7600 },
  { name: 'Ojo-Shibiri', value: 'Ojo-Shibiri', price: 7100 },
  { name: 'Ojo-Tedi Town', value: 'Ojo-Tedi Town', price: 5800 },
  { name: 'Ojo-Trade Fair', value: 'Ojo-Trade Fair', price: 6000 },
  { name: 'Ojo-Volks', value: 'Ojo-Volks', price: 6200 },
  { name: 'Ojodu', value: 'Ojodu', price: 6000 },
  { name: 'Ojokoro', value: 'Ojokoro', price: 7300 },
  { name: 'Ojota', value: 'Ojota', price: 4800 },
  { name: 'Okokomaiko', value: 'Okokomaiko', price: 7400 },
  { name: 'Okota', value: 'Okota', price: 5300 },
  { name: 'Omole Phase 1', value: 'Omole Phase 1', price: 4000 },
  { name: 'Omole Phase 2', value: 'Omole Phase 2', price: 4000 },
  { name: 'Oregun', value: 'Oregun', price: 3500 },
  { name: 'Oreyo- Igbe', value: 'Oreyo- Igbe', price: 7800 },
  { name: 'Orile Iganmu', value: 'Orile Iganmu', price: 3500 },
  { name: 'Osapa (Lekki)', value: 'Osapa (Lekki)', price: 2000 },
  { name: 'Oshodi-Bolade', value: 'Oshodi-Bolade', price: 4500 },
  { name: 'Oshodi-Isolo', value: 'Oshodi-Isolo', price: 4500 },
  { name: 'Oshodi-Mafoluku', value: 'Oshodi-Mafoluku', price: 4500 },
  { name: 'Oshodi-Orile', value: 'Oshodi-Orile', price: 4500 },
  { name: 'Oshodi-Shogunle', value: 'Oshodi-Shogunle', price: 4500 },
  { name: 'Palmgrove-Onipanu', value: 'Palmgrove-Onipanu', price: 3500 },
  { name: 'Sari-Iganmu', value: 'Sari-Iganmu', price: 3400 },
  { name: 'Satelite-Town', value: 'Satelite-Town', price: 4500 },
  { name: 'Shomolu', value: 'Shomolu', price: 3500 },
  { name: 'Surulere (Adeniran Ogunsanya)', value: 'Surulere (Adeniran Ogunsanya)', price: 3000 },
  { name: 'Surulere (Aguda)', value: 'Surulere (Aguda)', price: 3000 },
  { name: 'Surulere (Bode Thomas)', value: 'Surulere (Bode Thomas)', price: 3000 },
  { name: 'Surulere (Fatia Shitta)', value: 'Surulere (Fatia Shitta)', price: 3000 },
  { name: 'Surulere (Idi Araba)', value: 'Surulere (Idi Araba)', price: 3000 },
  { name: 'Surulere (Ijesha)', value: 'Surulere (Ijesha)', price: 4000 },
  { name: 'Surulere (Iponri)', value: 'Surulere (Iponri)', price: 3000 },
  { name: 'Surulere (Itire)', value: 'Surulere (Itire)', price: 3000 },
  { name: 'Surulere (Lawanson)', value: 'Surulere (Lawanson)', price: 4000 },
  { name: 'Surulere (Masha)', value: 'Surulere (Masha)', price: 3000 },
  { name: 'Surulere (Ogunlana drive)', value: 'Surulere (Ogunlana drive)', price: 3000 },
  { name: 'Surulere (Ojuelegba)', value: 'Surulere (Ojuelegba)', price: 3000 },
  { name: 'VI (Adetokunbo Ademola)', value: 'VI (Adetokunbo Ademola)', price: 2000 },
  { name: 'VI (Ahmed Bello way)', value: 'VI (Ahmed Bello way)', price: 2000 },
  { name: 'VI (Bishop Aboyade Cole)', value: 'VI (Bishop Aboyade Cole)', price: 2000 },
  { name: 'VI(Ajose Adeogun)', value: 'VI(Ajose Adeogun)', price: 2000 },
  { name: 'VI(Akin Adeshola)', value: 'VI(Akin Adeshola)', price: 2000 },
  { name: 'VI(Bishop Oluwale)', value: 'VI(Bishop Oluwale)', price: 2000 },
  { name: 'VI(Yusuf Abiodun)', value: 'VI(Yusuf Abiodun)', price: 2000 },
  { name: 'Victoria Island (Adeola Odeku)', value: 'Victoria Island (Adeola Odeku)', price: 2000 },
  { name: 'Victoria Island (Kofo Abayomi)', value: 'Victoria Island (Kofo Abayomi)', price: 2000 },
  { name: 'Yaba- Abule Ijesha', value: 'Yaba- Abule Ijesha', price: 3500 },
  { name: 'Yaba- Fadeyi', value: 'Yaba- Fadeyi', price: 3000 },
  { name: 'Yaba-(Sabo)', value: 'Yaba-(Sabo)', price: 3000 },
  { name: 'Yaba-(Unilag)', value: 'Yaba-(Unilag)', price: 3000 },
  { name: 'Yaba-Abule Oja', value: 'Yaba-Abule Oja', price: 3000 },
  { name: 'Yaba-Adekunle', value: 'Yaba-Adekunle', price: 3000 },
  { name: 'Yaba-Akoka', value: 'Yaba-Akoka', price: 3000 },
  { name: 'Yaba-Alagomeju', value: 'Yaba-Alagomeju', price: 3000 },
  { name: 'Yaba-College of Education', value: 'Yaba-College of Education', price: 3000 },
  { name: 'Yaba-Commercial Avenue', value: 'Yaba-Commercial Avenue', price: 3000 },
  { name: 'Yaba-Folagoro', value: 'Yaba-Folagoro', price: 3000 },
  { name: 'Yaba-Herbert Macaulay Way', value: 'Yaba-Herbert Macaulay Way', price: 3000 },
  { name: 'Yaba-Jibowu', value: 'Yaba-Jibowu', price: 3000 },
  { name: 'Yaba-Makoko', value: 'Yaba-Makoko', price: 3000 },
  { name: 'Yaba-Murtala Muhammed Way', value: 'Yaba-Murtala Muhammed Way', price: 3000 },
  { name: 'Yaba-Onike Iwaya', value: 'Yaba-Onike Iwaya', price: 3000 },
  { name: 'Yaba-Oyingbo', value: 'Yaba-Oyingbo', price: 3000 },
  { name: 'Yaba-Tejuosho', value: 'Yaba-Tejuosho', price: 3000 },
  { name: 'Yaba-University Road', value: 'Yaba-University Road', price: 3000 },
  { name: 'Yaba-Yabatech', value: 'Yaba-Yabatech', price: 3000 },
  { name: 'Agbara', value: 'Agbara', price: 7550 }
];

type cities = { name: string; value: string; price: number }[];
type states = { name: string; value: string; cities: cities }[];

export const states: states | any = [
  { name: 'Abia', value: 'Abia', cities: [] },
  { name: 'Abuja', value: 'Abuja', cities: [] },
  { name: 'Adamawa', value: 'Adamawa', cities: [] },
  { name: 'Akwa Ibom', value: 'Akwa Ibom', cities: [] },
  { name: 'Anambra', value: 'Anambra', cities: [] },
  { name: 'Bauchi', value: 'Bauchi', cities: [] },
  { name: 'Bayelsa', value: 'Bayelsa', cities: [] },
  { name: 'Benue', value: 'Benue', cities: [] },
  { name: 'Borno', value: 'Borno', cities: [] },
  { name: 'Cross River', value: 'Cross River', cities: [] },
  { name: 'Delta', value: 'Delta', cities: [] },
  { name: 'Ebonyi', value: 'Ebonyi', cities: [] },
  { name: 'Edo', value: 'Edo', cities: [] },
  { name: 'Ekiti', value: 'Ekiti', cities: [] },
  { name: 'Enugu', value: 'Enugu', cities: [] },
  { name: 'Gombe', value: 'Gombe', cities: [] },
  { name: 'Imo', value: 'Imo', cities: [] },
  { name: 'Jigawa', value: 'Jigawa', cities: [] },
  { name: 'Kaduna', value: 'Kaduna', cities: [] },
  { name: 'Kano', value: 'Kano', cities: [] },
  { name: 'Katsina', value: 'Katsina', cities: [] },
  { name: 'Kebbi', value: 'Kebbi', cities: [] },
  { name: 'Kogi', value: 'Kogi', cities: [] },
  { name: 'Kwara', value: 'Kwara', cities: [] },
  {
    name: 'Lagos',
    value: 'Lagos',
    cities
  },
  { name: 'Nasarawa', value: 'Nasarawa', cities: [] },
  { name: 'Niger', value: 'Niger', cities: [] },
  { name: 'Ogun', value: 'Ogun', cities: [] },
  { name: 'Ondo', value: 'Ondo', cities: [] },
  { name: 'Osun', value: 'Osun', cities: [] },
  { name: 'Oyo', value: 'Oyo', cities: [] },
  { name: 'Plateau', value: 'Plateau', cities: [] },
  { name: 'Rivers', value: 'Rivers', cities: [] },
  { name: 'Sokoto', value: 'Sokoto', cities: [] },
  { name: 'Taraba', value: 'Taraba', cities: [] },
  { name: 'Yobe', value: 'Yobe', cities: [] },
  { name: 'Zamfara', value: 'Zamfara', cities: [] }
];
