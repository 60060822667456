import config from 'config';
import { client } from '..';

export const getWallet = async (
  userId: string
): Promise<ApiResponse<IWallet | null> | undefined> => {
  return await client<ApiResponse<IWallet | null>>(
    `${config.AUTH_BASE_URL}/api/finance/${userId}/wallet`,
    'GET'
  );
};
export const topUpWallet = async (
  userId: string,
  amount: number,
  topupReason?: string,
  orderNumber?: string
): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/finance/wallet/admin`,
    'POST',
    {
      body: { userId, amount, orderNumber, topupReason }
    }
  );
};
export const getTransactionHistory = async (
  userId: string,
  admin = 'customer',
  page?: number,
  size?: number,
  type?: string,
  startDate?: string,
  endDate?: string,
  transactionType?: string,
  balanceStatus?: string,
  searchKey?: string
): Promise<ApiResponse<ITransactionData | null> | undefined> => {
  return await client<ApiResponse<ITransactionData | null>>(
    `${config.AUTH_BASE_URL}/api/finance/${userId}/history?domain=${admin}&type=${
      type ?? ''
    }&page=${page}&size=${size}&transactionType=${transactionType ?? ''}&balanceStatus=${
      balanceStatus ?? ''
    }&startDate=${startDate ?? ''}&endDate=${endDate ?? ''}&searchKey=${searchKey ?? ''}`,
    'GET'
  );
};

export const deductWallet = async (
  payload: IDeductWallet
): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/finance/wallet/pharmacy/withdrawal`,
    'POST',
    {
      body: payload
    }
  );
};

export const adminDeductFromWallet = async (
  pharmacyId: string,
  payload: IDeductWallet
): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/finance/wallet/${pharmacyId}/deduct`,
    'PUT',
    {
      body: payload
    }
  );
};
