import { makeObservable, observable, flow, action } from 'mobx';
import { toast } from 'react-hot-toast';
import { getUserConsultations, initiateConsultationPayment } from 'requests/consultations';
import { verifyPayment } from 'requests/order';
import { contactPharmacist } from 'requests/user';
import { RootStore } from 'store';
import { parseError } from 'utils';
import Alert from 'utils/Alert';

export class ConsultationStore {
  consultations: IConsultation[] = [];
  isPaymentModalOpen = false;
  appointmentDetails: IContactPharmacist = {
    reason: '',
    media: ''
  };
  isConsultationPaymentSuccessModalOpen = false;
  isTalktoDoctorOpen = false;
  consultationVendor = '';
  isConsultationAddCommentModalOpen = false;
  consultationId = '';

  filterOptions = {
    status: [],
    customerSource: [],
    type: [],
    partnerType: []
  } as Record<string, string[]>;

  isLoadingState = {
    getUserConsultations: false,
    contactPharmacist: false,
    initiatePayment: false,
    verifyConsultationPayment: false
  };

  currentPage = 1;
  pageLimit = 10;
  startDate = '';
  endDate = '';

  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeObservable(this, {
      consultations: observable,
      isLoadingState: observable,
      isPaymentModalOpen: observable,
      appointmentDetails: observable,
      isConsultationPaymentSuccessModalOpen: observable,
      isTalktoDoctorOpen: observable,
      consultationVendor: observable,
      isConsultationAddCommentModalOpen: observable,
      consultationId: observable,
      currentPage: observable,
      pageLimit: observable,
      startDate: observable,
      endDate: observable,
      filterOptions: observable,

      toggleOpenPaymentModal: action.bound,
      setAppointmentDetails: action.bound,
      toggleConsultationPaymentSuccessModal: action.bound,
      toggleTalktoDoctorModal: action.bound,
      toggleConsultationAddCommentModal: action.bound,
      setCurrentPage: action.bound,
      setPageLimit: action.bound,
      setDateFilter: action.bound,
      setFilterOptions: action.bound,
      resetFilterOptions: action.bound,
      setAllFilterOptions: action.bound,

      getUserConsultations: flow.bound,
      contactPharmacist: flow.bound,
      initiatePayment: flow.bound,
      verifyConsultationPayment: flow.bound
    });
    this.rootStore = rootStore;
  }

  setFilterOptions = (option: string, id: string) => {
    if (this.filterOptions[id].includes(option)) {
      this.filterOptions[id] = this.filterOptions[id].filter((item: string) => item !== option);
      return;
    }
    this.filterOptions[id].push(option);
  };

  setAllFilterOptions = (option: string, id: string, checked?: boolean) => {
    if (checked) {
      if (!this.filterOptions[id].includes(option)) {
        this.filterOptions[id].push(option);
      }
    } else {
      this.filterOptions[id] = [];
    }
  };

  resetFilterOptions = () => {
    this.filterOptions = {
      status: [],
      customerSource: [],
      type: [],
      partnerType: []
    };
  };

  setAppointmentDetails(data: IContactPharmacist) {
    this.appointmentDetails = data;
  }

  toggleOpenPaymentModal() {
    this.isPaymentModalOpen = !this.isPaymentModalOpen;
  }

  toggleConsultationPaymentSuccessModal() {
    this.isConsultationPaymentSuccessModalOpen = !this.isConsultationPaymentSuccessModalOpen;
  }

  toggleTalktoDoctorModal(provider?: string) {
    if (provider) {
      this.consultationVendor = provider;
    }
    this.isTalktoDoctorOpen = !this.isTalktoDoctorOpen;
  }

  toggleConsultationAddCommentModal(id?: string) {
    if (id) {
      this.consultationId = id;
    }
    this.isConsultationAddCommentModalOpen = !this.isConsultationAddCommentModalOpen;
  }

  setCurrentPage(page: number) {
    this.currentPage = page;
  }

  setPageLimit(limit: number) {
    this.pageLimit = limit;
  }

  setDateFilter(type: string, date: string) {
    type === 'start' ? (this.startDate = date) : (this.endDate = date);
  }

  *getUserConsultations() {
    this.isLoadingState.getUserConsultations = true;
    try {
      const res = (yield getUserConsultations()) as ApiResponse<IConsultation | any>;
      if (res && res?.status) {
        this.consultations = res.data;
      }
    } catch (error) {
      toast.error(parseError(error));
    } finally {
      this.isLoadingState.getUserConsultations = false;
    }
  }

  *contactPharmacist(data: IContactPharmacist) {
    this.isLoadingState.contactPharmacist = true;
    try {
      const res = (yield contactPharmacist(data)) as ApiResponse<string>;
      if (res && res.status) toast.success(`${res.message}`, { position: 'top-right' });
    } catch (error) {
      toast.error(parseError(error), { position: 'top-right' });
    } finally {
      this.isLoadingState.contactPharmacist = false;
    }
  }

  *initiatePayment(consultationType: string) {
    const data = {
      date: this.appointmentDetails?.appointmentDate,
      time: this.appointmentDetails?.appointmentTime,
      symptoms: this.appointmentDetails?.reason,
      channel: this.appointmentDetails?.modeOfContact,
      consultationType,
      image: this.appointmentDetails?.media,
      vendor: this.consultationVendor
    };
    try {
      this.isLoadingState.initiatePayment = true;
      const res = (yield initiateConsultationPayment(data)) as ApiResponse<{
        paymentUrl: string;
      }>;
      if (res && res.status) {
        Alert.info({ message: res?.message });
        this.setAppointmentDetails({ reason: '' });
        window.location.href = res.data?.paymentUrl as string;
      }
    } catch (error) {
      Alert.error({ message: parseError(error) });
    } finally {
      this.isLoadingState.initiatePayment = false;
    }
  }

  *verifyConsultationPayment(query: string, redirect: () => void) {
    try {
      this.isLoadingState.verifyConsultationPayment = true;
      const res = ((yield verifyPayment(`${query}`)) as ApiResponse<string>) || undefined;
      if (res && res.status) {
        Alert.success({ message: res.message });
        this.toggleConsultationPaymentSuccessModal();
        this.consultationVendor = '';
        return;
      }
      redirect();
    } catch (error) {
      Alert.error({ message: parseError(error) });
    } finally {
      this.isLoadingState.verifyConsultationPayment = false;
    }
  }
}
