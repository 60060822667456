import { Suspense, lazy } from 'react';
import Loading from 'components/loading';
import CreateBundleItem from 'domains/adminDashboard/inventory/components/CreateBundleItem';
import SchedulesModal from 'domains/adminDashboard/orderpage/components/subscription/SchedulesModal';
import AddPartnerModalType from 'domains/adminDashboard/partners/components/AddPartnerModalType';
import UploadBanner from 'domains/adminDashboard/campaign/components/UploadBanner';
import BannerStatusActivation from 'domains/adminDashboard/campaign/components/BannerStatusActivation';
import DeleteBanner from 'domains/adminDashboard/campaign/components/DeleteBanner';
import SuspendPartnerModal from 'domains/adminDashboard/partners/components/SuspendPartnerModal';
import ViewPartnerMemberDetails from 'domains/adminDashboard/partners/components/ViewPartnerMemberDetails';
import AddPartnerMemberModal from 'domains/adminDashboard/partners/components/AddPartnerMemberModal';

import CancelItemModal from 'domains/adminDashboard/orderpage/components/Edit/CancelItemModal';
import VerifyCancelPaidItemModal from 'domains/adminDashboard/orderpage/components/Edit/VerifyCancelPaidItemModal';
import RefundCancelledItemModal from 'domains/adminDashboard/orderpage/components/Edit/RefundCancelledItemModal';

import CloseSubscriptionModal from 'domains/adminDashboard/orderpage/components/subscription/CloseSubscriptionModal';
import WalletInformationForm from 'domains/adminDashboard/partners/components/WalletInformationForm';

const ViewBundleDetails = lazy(
  () => import('domains/adminDashboard/inventory/components/ViewBundleDetails')
);
const AddItemModal = lazy(() => import('domains/adminDashboard/inventory/components/AddItemModal'));
const PartnerJoinModal = lazy(() => import('components/accountform/partnerjoinmodal'));
const RequestJoinNetworkModal = lazy(
  () => import('domains/clientDashboard/members/components/RequestJoinNetworkModal')
);

const ViewImageModal = lazy(() => import('components/placeorderform/components/ViewImageModal'));
const ItemImagesModal = lazy(
  () => import('domains/adminDashboard/inventory/components/ItemImagesModal')
);

const DeletePartnerModal = lazy(
  () => import('domains/adminDashboard/partners/components/DeletePartnerModal')
);
const LandingModal = lazy(
  () => import('domains/clientDashboard/placeanorder/components/LandingModal')
);
const FamilyandFriendModal = lazy(
  () => import('domains/clientDashboard/account/components/FamilyandFriendModal')
);
const RemoveFandFModal = lazy(
  () => import('domains/clientDashboard/account/components/RemoveFandFModal')
);
const BookAPharmacyModal = lazy(
  () => import('domains/clientDashboard/contact/components/BookAPharmacyModal')
);
const DiscountOptions = lazy(
  () => import('domains/clientDashboard/dashboard/components/discountOptions')
);
const OrderSummary = lazy(
  () => import('domains/clientDashboard/dashboard/components/OrderSummary')
);
const CancelOrderModal = lazy(
  () =>
    import('domains/clientDashboard/dashboard/components/OrderSummary/components/CancelOrderModal')
);
const PaymentOption = lazy(
  () => import('domains/clientDashboard/dashboard/components/paymentOption')
);
const ReorderModal = lazy(
  () => import('domains/clientDashboard/dashboard/components/ReorderModal')
);
const SubscriptionSummary = lazy(
  () => import('domains/clientDashboard/dashboard/components/SubscriptionSummary')
);
const PauseSubscriptionModal = lazy(
  () =>
    import(
      'domains/clientDashboard/dashboard/components/SubscriptionSummary/components/PauseSubscriptionModal'
    )
);
const ResumeSubscriptionModal = lazy(
  () =>
    import(
      'domains/clientDashboard/dashboard/components/SubscriptionSummary/components/ResumeSubscriptionModal'
    )
);
const CheckoutModal = lazy(
  () => import('domains/clientDashboard/placeanorder/components/CheckoutModal')
);
const CheckoutRequestModal = lazy(
  () => import('domains/clientDashboard/placeanorder/components/CheckoutRequestModal')
);
const OrderRequestModal = lazy(
  () => import('domains/clientDashboard/placeanorder/components/OrderRequestModal')
);
const PharmaPackModal = lazy(
  () => import('domains/clientDashboard/placeanorder/components/PharmaPackModal')
);
const RecurringModal = lazy(
  () => import('domains/clientDashboard/placeanorder/components/RecurringModal')
);
const PaymentSuccessful = lazy(() => import('domains/verify/component/PaymentSuccessful'));
const DeliveryAddress = lazy(() => import('../receipentform/deliveryaddress'));
const CreateVoucher = lazy(() => import('domains/adminDashboard/customer/customerVoucher'));
const CustomerDetailsModal = lazy(
  () => import('domains/adminDashboard/customer/customerDetailsModal')
);
const CustomerWalletDetails = lazy(() => import('domains/adminDashboard/customer/walletDetails'));
const DeactivateCustomerModal = lazy(
  () => import('domains/adminDashboard/customer/deactivateCustomer')
);
const DeleteCustomerModal = lazy(() => import('domains/adminDashboard/customer/deleteCustomer'));
const CommentModal = lazy(() => import('domains/adminDashboard/orderpage/components/CommentModal'));
const CloseOrderModal = lazy(
  () => import('domains/adminDashboard/orderpage/components/CloseOrderModal')
);
const CreateItemModal = lazy(
  () => import('domains/adminDashboard/inventory/components/CreateItemModal')
);
const AddItemsBulkModal = lazy(() => import('../bulkupload'));
const DeleteItemModal = lazy(
  () => import('domains/adminDashboard/inventory/components/DeleteItemModal')
);
const CreateRunnerModal = lazy(
  () => import('domains/adminDashboard/runners/components/CreateRunnerModal')
);
const CustomerRunnerdetails = lazy(
  () => import('domains/adminDashboard/runners/components/CustomerRunnerdetails')
);
const DeactivateRunnerModal = lazy(
  () => import('domains/adminDashboard/runners/components/DeactivateRunnerModal')
);
const ViewMemberDetails = lazy(
  () => import('domains/clientDashboard/members/components/ViewMemberDetails')
);
const ActivateCustomerModal = lazy(
  () => import('domains/adminDashboard/customer/activateCustomer')
);
const ViewInventorySummary = lazy(
  () => import('domains/adminDashboard/inventory/components/ViewInventorySummary')
);
const ViewPartnerDetails = lazy(
  () => import('domains/adminDashboard/partners/components/ViewPartnerDetails')
);
const AddBranchModal = lazy(
  () => import('domains/adminDashboard/partners/components/AddBranchModal')
);
const AddPartnersModal = lazy(
  () => import('domains/adminDashboard/partners/components/AddPartnersModal')
);
const StatusActivationModal = lazy(
  () => import('domains/adminDashboard/partners/components/StatusActivationModal')
);
const AddPartnerTypesModal = lazy(
  () => import('domains/adminDashboard/partners/components/AddPartnerTypesModal')
);
const CreditInformationForm = lazy(
  () => import('domains/adminDashboard/partners/components/CreditInformationForm')
);
const TopUpModal = lazy(() => import('domains/clientDashboard/wallets/components/TopUpModal'));
const RequestPayoutModal = lazy(
  () => import('domains/clientDashboard/wallets/components/RequestPayoutModal')
);
const PaidOnCreditModal = lazy(
  () => import('domains/adminDashboard/orderpage/components/payment/PaidOnCreditModal')
);
const PaymentWalletModal = lazy(
  () => import('domains/clientDashboard/dashboard/components/paymentWallet')
);
const PaymentSuccessModal = lazy(
  () => import('domains/clientDashboard/dashboard/components/paymentWallet/PaymentSuccessModal')
);
const TransactionReceipt = lazy(
  () => import('domains/clientDashboard/dashboard/components/OrderCard/TransactionReceipt')
);

const PaymentModal = lazy(
  () => import('domains/clientDashboard/consultations/components/PaymentModal')
);
const CreateCouponModal = lazy(
  () => import('domains/adminDashboard/coupon/components/CreateCouponModal')
);
const UpdateCouponStatus = lazy(
  () => import('domains/adminDashboard/coupon/components/UpdateCouponStatus')
);
const AdminTransactionReceipt = lazy(
  () => import('domains/adminDashboard/orderpage/components/AdminTransactionReceipt')
);

const PlaceOrderForPartnerModal = lazy(
  () => import('domains/adminDashboard/partners/components/PlaceOrderForPartnerModal')
);
const AdminRefundFormModal = lazy(
  () => import('domains/adminDashboard/orderpage/components/refund/RefundFormModal')
);

const AdminRefundOptionsModal = lazy(
  () => import('domains/adminDashboard/orderpage/components/refund/RefundOptionsModal')
);

const DeleteProductsModal = lazy(
  () => import('domains/adminDashboard/inventory/components/DeleteProductModal')
);

const ModalBank = () => {
  return (
    <Suspense fallback={<Loading />}>
      <OrderSummary />
      <CancelOrderModal />
      <FamilyandFriendModal />
      <RemoveFandFModal />
      <PaymentOption />
      <DiscountOptions />
      <CheckoutModal />
      <RecurringModal />
      <CheckoutRequestModal />
      <OrderRequestModal />
      <PharmaPackModal />
      <DeliveryAddress />
      <SubscriptionSummary />
      <PaymentSuccessful />
      <BookAPharmacyModal />
      <PauseSubscriptionModal />
      <ResumeSubscriptionModal />
      <ReorderModal />
      <LandingModal />
      <CreateVoucher />
      <CustomerDetailsModal />
      <DeleteCustomerModal />
      <DeactivateCustomerModal />
      <CommentModal />
      <CloseSubscriptionModal />
      <CloseOrderModal />
      <CreateItemModal />
      <AddItemsBulkModal />
      <SchedulesModal />
      <DeleteItemModal />
      <CreateRunnerModal />
      <CustomerRunnerdetails />
      <DeactivateRunnerModal />
      <ViewMemberDetails />
      <ActivateCustomerModal />
      <ViewInventorySummary />
      <ViewPartnerDetails />
      <AddBranchModal />
      <AddPartnersModal />
      <StatusActivationModal />
      <AddPartnerTypesModal />
      <CreditInformationForm />
      <TopUpModal />
      <RequestPayoutModal />
      <PaidOnCreditModal />
      <PaymentWalletModal />
      <PaymentSuccessModal />
      <TransactionReceipt />
      <AdminTransactionReceipt />
      <PaymentModal />
      <CreateCouponModal />
      <UpdateCouponStatus />
      <ItemImagesModal />
      <DeletePartnerModal />
      <CustomerWalletDetails />
      <RequestJoinNetworkModal />
      <ViewImageModal />
      <AddItemModal />
      <CreateBundleItem />
      <ViewBundleDetails />
      <PartnerJoinModal />
      <AddPartnerModalType />
      <UploadBanner />
      <BannerStatusActivation />
      <DeleteBanner />
      <SuspendPartnerModal />
      <PlaceOrderForPartnerModal />
      <ViewPartnerMemberDetails />
      <AddPartnerMemberModal />
      <DeleteBanner />
      <SuspendPartnerModal />
      <AdminRefundFormModal />
      <AdminRefundOptionsModal />
      <CancelItemModal />
      <VerifyCancelPaidItemModal />
      <RefundCancelledItemModal />
      <DeleteProductsModal />
      <WalletInformationForm />
    </Suspense>
  );
};

export default ModalBank;
