import { InfoIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Input,
  ModalFooter,
  Text,
  Tooltip,
  VStack
} from '@chakra-ui/react';
import PRModal from 'components/modal';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useStore } from 'store';
import { parseAmount } from 'utils';
import Permissions from 'utils/permissions';
import DeductWallet from './DeductWallet';
import { useNavigate } from 'react-router-dom';

export const ToolTipInfo = ({ text }: { text: string }) => {
  return (
    <Box bg={`white`} color={`black`} borderRadius={`8px`}>
      <Text
        fontSize={{
          base: '11.5px',
          md: '12px'
        }}
        fontWeight={`400`}
      >
        {' '}
        <span style={{ whiteSpace: 'nowrap' }}>{text}</span>
      </Text>
    </Box>
  );
};
const WalletInformationForm = () => {
  const {
    AuthStore,
    PartnerStore: { toggleWalletInfoModal, isOpenWalletInfoModal, selectedPartner, getAllPartners },
    FinanceStore: { topUpWallet, deductFromWallet, isLoadingState, wallet }
  } = useStore();
  const [topWallet, setTopWallet] = useState(0);
  const [orderNumber, setOrderNumber] = useState('');
  const partnerId = selectedPartner?.partnerId;
  const permissions = new Permissions(AuthStore);
  const navigate = useNavigate();
  const userId = partnerId;
  const type = 'wallet-';
  const handleTopUpWallet = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isNaN(topWallet) || topWallet <= 0) {
      return;
    }
    const amount = parseFloat(topWallet as any);
    try {
      await topUpWallet(userId as string, amount, orderNumber);
      await getAllPartners();
      await toggleWalletInfoModal(userId as string);
      setTopWallet(0);
      setOrderNumber('');
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeductFromWallet = async (amount: number, reason: string) => {
    try {
      await deductFromWallet(userId as string, amount, reason);
      await getAllPartners();
      await toggleWalletInfoModal(userId as string);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <PRModal
      title="Wallet Information"
      open={isOpenWalletInfoModal}
      onClose={() => toggleWalletInfoModal(partnerId as string)}
    >
      <Card
        border="1.5px solid #F5F5F5"
        p=".6rem"
        w="100%"
        mb="1rem"
        display="flex"
        align="center"
        justify="center"
      >
        <Flex alignItems="center" width="100%" justify="center" gap="3rem">
          <Flex align="start" justify="start" gap=".5rem">
            <Tooltip
              label={<ToolTipInfo text={`Funds currently available for withdrawal`} />}
              bg={`white`}
              placement="left-end"
              maxW="800px"
            >
              <InfoIcon fontSize={15} color="primary.main" mt={1.5} />
            </Tooltip>
            <Box
              fontSize={{
                base: '12px',
                md: '14px'
              }}
            >
              <Text fontWeight="100" color="gray">
                EARNED
              </Text>
              <Text fontWeight="400" color="primary.main">
                {parseAmount(wallet?.earnedBalance as number)}
              </Text>
            </Box>
          </Flex>
          <Divider height="5rem" orientation="vertical" size="4rem" />
          <Flex align="start" justify="start" gap=".5rem">
            <Tooltip
              label={
                <ToolTipInfo
                  text={`Funds that will be available for withdrawal once items are delivered`}
                />
              }
              bg={`white`}
              placement="right-end"
              maxW="800px"
            >
              <InfoIcon fontSize={15} color="primary.main" mt={1.5} />
            </Tooltip>
            <Box
              fontSize={{
                base: '12px',
                md: '14px'
              }}
            >
              <Text fontWeight="100" color="grey">
                PENDING
              </Text>
              <Text fontWeight="400" color="primary.main">
                {parseAmount(wallet?.pendingBalance as number)}
              </Text>
            </Box>
          </Flex>
        </Flex>
      </Card>
      <VStack
        align="start"
        overflowY="scroll"
        sx={{
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }}
        mb="4rem"
        h="auto"
        gap=".6rem"
      >
        <Box>
          <Text fontWeight="450" fontSize="15px">
            Top up wallet
          </Text>
        </Box>
        <Card border="1.5px solid #F5F5F5" p=".6rem" w="100%" mb={{ base: '10rem', md: '1rem' }}>
          <Flex>
            <Text
              fontSize={{
                base: '13px',
                md: '14px'
              }}
              fontWeight="500"
            >
              Wallet balance
            </Text>
            <Text
              fontSize={{
                base: '13px',
                md: '14px'
              }}
              ml="auto"
              color="primary.main"
              fontWeight="500"
            >
              {parseAmount(wallet?.balance ?? 0)}
            </Text>
          </Flex>
          {permissions.hasFeatureAccess('DeductPartnersWallet') &&
            selectedPartner.partnerType === 'Pharmacy' && (
              <Box
                fontSize={{
                  base: '12px',
                  md: '14px'
                }}
                mt="1rem"
              >
                <Text
                  fontSize={{
                    base: '13px',
                    md: '14px'
                  }}
                >
                  Order number
                </Text>
                <Flex gap="1rem">
                  <Input
                    name="orderNumber"
                    py="1.4rem"
                    bg="#F3F5F7"
                    placeholder="Enter order number"
                    type="text"
                    value={orderNumber}
                    onChange={(e: any) => setOrderNumber(e.target.value)}
                    fontSize={{ base: '1rem', md: '0.875rem' }}
                  />
                </Flex>
              </Box>
            )}
          <Box
            fontSize={{
              base: '12px',
              md: '14px'
            }}
            mt="1rem"
          >
            <Text
              fontSize={{
                base: '13px',
                md: '14px'
              }}
            >
              Amount
            </Text>
            <form onSubmit={handleTopUpWallet}>
              <Flex gap="1rem">
                <Input
                  name="topWallet"
                  py="1.4rem"
                  bg="#F3F5F7"
                  type="number"
                  value={topWallet}
                  onChange={(e: any) => setTopWallet(e.target.value)}
                  fontSize={{ base: '1rem', md: '0.875rem' }}
                />
                <Button isLoading={isLoadingState.topUpWallet} type="submit">
                  Top up
                </Button>
              </Flex>
            </form>
          </Box>
        </Card>
        {permissions.hasFeatureAccess('DeductPartnersWallet') &&
          selectedPartner.partnerType === 'Pharmacy' && (
            <DeductWallet submit={handleDeductFromWallet} isLoading={isLoadingState.deductWallet} />
          )}

        <ModalFooter
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            bg: 'white'
          }}
          position="absolute"
          bottom="0"
          left="0"
        >
          <Flex
            flexDir={{
              base: 'column',
              md: 'row'
            }}
            align="center"
            justifyContent="space-between"
          >
            <Button
              onClick={() => toggleWalletInfoModal(partnerId as string)}
              bg="transparent"
              color="primary.main"
              cursor="pointer"
            >
              Cancel
            </Button>
            <Flex gap="1rem">
              <Button
                onClick={() => {
                  navigate(`admin/partners/${partnerId}/${type}transaction-history`);
                  toggleWalletInfoModal(partnerId as string);
                }}
                variant="outline"
              >
                Transaction history
              </Button>
              <Flex
                fontSize={{
                  base: '14px',
                  md: '16px'
                }}
                display="none"
              >
                <Button type="submit" form="partner-form">
                  Edit
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </ModalFooter>
      </VStack>
    </PRModal>
  );
};

export default observer(WalletInformationForm);
