import { action, flow, makeObservable, observable } from "mobx";
import { RootStore } from "..";
import toast from "react-hot-toast";
import {
getDrugById,
postInventory,
editInventory,
deleteInventory,
uploadImages,
deleteImageKey,
addInventoryBulkUpload,
editBundle,
deleteBundle
} from "../../requests/inventory";
import { parseError } from "../../utils";

type QueryParams = {
  [key: string]: string | undefined;
};
export class InventoryStore {
  drug = {} as IDrug;
  selectedDrug = {} as IDrugSearchResult;
  selectedInventoryItem = {} as IDrug;
  selectedbundleItem = {} as IBundle;
  isInventory = false as boolean;
  bundleItem = {
    name: '',
    description: '',
    category: '',
    subCategory: '',
    mobileCategory: [],
    drugs: [],
    supplier: '',
    image: '',
    canBeModified: false
  } as IBundle;
  inventoryItem = {
    brandName: "",
    category: "",
    genericName: "",
    manufacturer: "",
    medicationSummary: "",
    popularName: "",
    subCategory: "",
    warning: "",
    dosageForm: "",
    unit: [],
    mobileCategory: [],
    quantityInFullPack: "",
    quantityInPack: "",
    strength: "",
    costPrice: 0,
    price: 0,
    prescriptionRequired: false,
    drugImages: Array.from({ length: 6 }, () => ({ key: '', fileUrl: '' }))
  } as IDrug;
  searchQuery = "";
  queryString = "";
  newBundleItem = "";
  editBundleItem = "" as string;
  newInventoryItem = "";
  editInventoryItem = "" as string;
  inventory = [] as IDrug[];
  bundles = [] as IBundle[];
  count = 1;
  totalInventoryCount = 0;
  totalBundleCount = 0;
  package = "";
  updatePrice = 0;
  isViewItemImagesModalOpen = false;
  isViewItemSummaryModalOpen = false;
  isViewBundleDetails = false;
  isCreateBundleItemModal = false;
  isAddItemModalOpen = false;
  isAddSingleItemModalOpen = false;
  isAddItemBulkModalOpen = false;
  isEditItemModalOpen = false;
  isDeleteItemModalOpen = false;
  isViewImageOpen = false;
  totalDrugsCount = 0;
  currentPage = 1;
  pageLimit = 10;
  isDeleteMedModalOpen = false;

  filterParams = {
    selectedCategory: "all",
    selectedDosage: "all",
    searchKey: "",
  };

  isLoading = {
    getAllDrugs: false,
    getDrugById: false,
    createBundle: false,
    editBundle: false,
    deleteBundle: false,
    postInventory: false,
    editInventory: false,
    uploadImages: false,
    deleteInventory: false,
    deleteImageKey: false
  };
  loading = false;
  isOpen = false;
  tooltipMessage = false;
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      drug: observable,
      selectedDrug: observable,
      filterParams: observable,
      searchQuery: observable,
      queryString: observable,
      totalInventoryCount: observable,
      totalBundleCount: observable,
      count: observable,
      package: observable,
      updatePrice: observable,
      inventory: observable,
      bundles: observable,
      loading: observable,
      isOpen: observable,
      tooltipMessage: observable,
      selectedInventoryItem: observable,
      selectedbundleItem: observable,
      isViewItemSummaryModalOpen: observable,
      isViewBundleDetails: observable,
      isCreateBundleItemModal: observable,
      isViewItemImagesModalOpen: observable,
      isAddSingleItemModalOpen: observable,
      isAddItemBulkModalOpen: observable,
      isEditItemModalOpen: observable,
      isDeleteItemModalOpen: observable,
      newInventoryItem: observable,
      editInventoryItem: observable,
      newBundleItem: observable,
      editBundleItem: observable,
      inventoryItem: observable,
      bundleItem: observable,
      totalDrugsCount: observable,
      currentPage: observable,
      pageLimit: observable,
      isInventory: observable,
      isViewImageOpen: observable,
      isAddItemModalOpen: observable,
      isDeleteMedModalOpen: observable,


      buildInventoryQuery: action.bound,
      setFilterParams: action.bound,
      selectDrug: action.bound,
      updatePackage: action.bound,
      updatedPrice: action.bound,
      updateCount: action.bound,
      addDrugToCart: action.bound,
      resetCount: action.bound,
      toggleMedicationItems: action.bound,
      updateTooltipMessage: action.bound,
      toggleAddSingleItemModal: action.bound,
      toggleAddItemBulkModal: action.bound,
      toggleViewItemSummaryModal: action.bound,
      toggleViewBundleDetails: action.bound,
      toggleViewItemImages: action.bound,
      toggleEditItemModal: action.bound,
      toggleDeleteItemModal: action.bound,
      toggleDeleteBundleModal: action.bound,
      setNewInventoryItem: action.bound,
      setToEditInventoryItem: action.bound,
      setInventoryItem: action.bound,
      setBundleItem: action.bound,
      reviewSellingPrice: action.bound,
      setNewBundleItem: action.bound,
      setToEditBundleItem: action.bound,
      setInventory: action.bound,
      setBundle: action.bound,
      setCurrentPage: action.bound,
      setPageLimit: action.bound,
      toggleImageModal: action.bound,
      toggleAddItemModal: action.bound,
      toggleCreateBundleItemModal: action.bound,
      toggleDeleteMedModal: action.bound,

      getDrugById: flow.bound,
      postInventory: flow.bound,
      editInventory: flow.bound,
      uploadImages: flow.bound,
      deleteInventory: flow.bound,
      deleteImageKey: flow.bound,
      addInventoryBulk: flow.bound,
      editBundle: flow.bound,
      deleteBundle: flow.bound
    });
    this.rootStore = rootStore;
  }

  setToEditInventoryItem(id: string) {
    this.editInventoryItem = id;
  }
  setNewInventoryItem(value: string) {
    this.newInventoryItem = value;
  }

  setInventoryItem(item: IDrug) {
    this.inventoryItem = item;
  }
  setToEditBundleItem(id: string) {
    this.editBundleItem = id;
  }
  setNewBundleItem(value: string) {
    this.newBundleItem = value;
  }

  setBundleItem(item: IBundle) {
    this.bundleItem = item;
  }

  setCurrentPage(page: number) {
    this.currentPage = page;
  }

  setPageLimit(limit: number) {
    this.pageLimit = limit;
  }
  toggleImageModal() {
    this.isViewImageOpen = !this.isViewImageOpen;
  }
  toggleDeleteMedModal(id?: string) {
    this.isDeleteMedModalOpen = !this.isDeleteMedModalOpen;
    if(id) {
      const items = this.inventory.find((item) => item._id === id);
      if (items) {
        this.selectedInventoryItem = items;
      }
    }
  }

  toggleAddSingleItemModal() {
    if (!this.isAddSingleItemModalOpen) {
      this.newInventoryItem = "newInventoryItem";
    } else {
      this.newInventoryItem = "";
      this.inventoryItem = {
        brandName: "",
        category: "",
        genericName: "",
        manufacturer: "",
        medicationSummary: "",
        popularName: "",
        subCategory: "",
        warning: "",
        dosageForm: "",
        unit: [],
        mobileCategory: [],
        quantityInFullPack: "",
        quantityInPack: "",
        strength: "",
        price: 0,
        prescriptionRequired: false,
        drugImages: Array.from({ length: 6 }, () => ({ key: '', fileUrl: '' }))
      } as IDrug;
    }

    this.isAddSingleItemModalOpen = !this.isAddSingleItemModalOpen;

  }
  toggleCreateBundleItemModal() {
    if (!this.isCreateBundleItemModal) {
      this.newBundleItem = "newBundleItem";
    } else {
      this.newBundleItem = "";
      this.bundleItem = {
        name: '',
        description: '',
        category: '',
        subCategory: '',
        mobileCategory: [],
        drugs: [],
        supplier: '',
        image: '',
        canBeModified: false
      } as IBundle;
    }

    this.isCreateBundleItemModal = !this.isCreateBundleItemModal;

  }

  toggleViewBundleDetails(id: string) {
    this.isViewBundleDetails = !this.isViewBundleDetails;
    const items = this.bundles.find((item) => item._id === id);
    if (items) {
      this.selectedbundleItem = items;
    }
  }
  toggleViewItemSummaryModal(id: string) {
    this.isViewItemSummaryModalOpen = !this.isViewItemSummaryModalOpen;
    const items = this.inventory.find((item) => item._id === id);
    if (items) {
      this.selectedInventoryItem = items;
    }
  }
  toggleViewItemImages(id: string) {
    this.isViewItemImagesModalOpen = !this.isViewItemImagesModalOpen;
    const items = this.inventory.find((item) => item._id === id);
    if (items) {
      this.selectedInventoryItem = items;
    }
  }

  toggleDeleteItemModal(id: string) {
    this.isDeleteItemModalOpen = !this.isDeleteItemModalOpen;
    const items = this.inventory.find((item) => item._id === id);
    if (items) {
      this.selectedInventoryItem = items;
    }
  }
  toggleDeleteBundleModal(id: string) {
    this.isDeleteItemModalOpen = !this.isDeleteItemModalOpen;
    const items = this.bundles.find((item) => item._id === id);
    if (items) {
      this.selectedbundleItem = items;
    }
  }
  toggleEditItemModal(id: string) {
    this.isEditItemModalOpen = !this.isEditItemModalOpen;
    const items = this.inventory.find((item) => item._id === id);
    if (items) {
      this.selectedInventoryItem = items;
    }
  }

  toggleAddItemModal() {
    this.isAddItemModalOpen = !this.isAddItemModalOpen;
  }

  toggleAddItemBulkModal() {
    this.isAddItemBulkModalOpen = !this.isAddItemBulkModalOpen;
  }

  updateTooltipMessage = (open = false) => {
    this.tooltipMessage = open;
  };

  toggleMedicationItems = (open = false) => {
    this.isOpen = open;
  };

  selectDrug = (drug: IDrugSearchResult) => {
    this.selectedDrug = drug;
    this.rootStore.CartStore.logs.search.push(this.searchQuery);
    if (drug.drugData) {
      this.rootStore.CartStore.logs.selectedDrug.push(drug.drugData);
    }
    this.toggleMedicationItems(false);
    this.searchQuery = "";
    this.tooltipMessage = false;
  };

  addDrugToCart = () => {
    this.selectedDrug = {} as IDrugSearchResult;

    this.count = 1;
    this.package = "";
    this.updatePrice = 0;
    this.rootStore.OrderStore.orderDate = '',
      this.tooltipMessage = false;
  };

  updatePackage = (unit: string) => {
    this.package = unit?.trim()?.toLowerCase();
  };
  updatedPrice = (price: number) => {
    this.updatePrice = price
    if (this.selectedDrug && this.selectedDrug.drugData) {
      this.selectedDrug.drugData.price = price;
    }

  }
  resetCount = (drug: IDrugSearchResult) => {
    this.selectedDrug = drug;
    this.count = 1;

  };

  reviewSellingPrice(sellingPrice: any, costPrice: any) {
    if (sellingPrice as unknown as number < costPrice as unknown as number) {
      toast.error('Selling price cannot be less than the cost price.');
      return false;
    }
    return true;
  }
  updateCount = (type: string) => {
    if (type === "plus") {
      this.count += 1;
    } else {
      if (this.count > 1) {
        this.count -= 1;
      }
    }
  };
  setBundle(data: any) {
    if (data && data.data) {
      this.bundles = data.data;
      this.totalBundleCount = data.totalCount;

    }
  }
  setInventory(data: any) {
    if (data && data.data) {
      this.inventory = data.data;
      this.totalInventoryCount = data.totalCount;

    }
  }

  setFilterParams(field: keyof typeof this.filterParams, value: string) {
    this.filterParams[field] = value;
    this.buildInventoryQuery();
  }
  buildInventoryQuery() {
    this.queryString = '';
    const queryParams: QueryParams = {};
    const searchKey = this.rootStore.AppStore.searchQuery.toLowerCase();
    const { selectedCategory, selectedDosage } = this.filterParams;
    if (selectedCategory !== "all") {
      queryParams["category"] = selectedCategory;
    }

    if (selectedDosage !== "all") {
      queryParams["dosage"] = selectedDosage;
    }
    if (searchKey.length > 2) {
      queryParams["searchKey"] = searchKey;
    }

    let isFirstParam = true;
    for (const key in queryParams) {
      if (Object.prototype.hasOwnProperty.call(queryParams, key)) {
        this.queryString += `${isFirstParam ? "?" : "&"}${key}=${queryParams[key]
          }`;
        isFirstParam = false;
      }
    }
  }

  *getDrugById(id: string) {
    this.isLoading.getDrugById = true;
    try {
      const { data } = yield getDrugById(id);
      this.drug = data;
    } catch (error) {
      const message = parseError(error);
      toast.error(message, {
        position: "top-right",
      });
    } finally {
      this.isLoading.getDrugById = false;
    }
  }

  *postInventory(body: IDrug) {
    this.isLoading.postInventory = true;
    try {
      const res = (yield postInventory(body)) as ApiResponse<IDrug>;
      if (res.status) {

        this.toggleAddSingleItemModal();
      }

      toast.success(`${res.message}`, {
        position: "top-right",
      });
    } catch (error) {
      const message = parseError(error);
      toast.error(message, {
        position: "top-right",
      });
    } finally {
      this.isLoading.postInventory = false;
    }
  }
  *addInventoryBulk(file: File) {
    this.isLoading.postInventory = true;
    try {
      const response = (yield addInventoryBulkUpload(file)) as ApiResponse<any>;

      if (response) {
        toast.success(`${response.message}`, {
          position: 'top-right'
        })
      }
    } catch (error: any) {
      toast.error(parseError(error?.response?.message), {
        position: 'top-right'
      });
    } finally {
      this.isLoading.postInventory = false;
    }
  }
  *editInventory(body: IDrug, drugGeneratedId: string) {
    this.isLoading.editInventory = true;
    try {
      delete body._id;
      const res = (yield editInventory(body, drugGeneratedId)) as ApiResponse<
        IDrug
      >;
      if (res.status) {
        this.toggleAddSingleItemModal();
        toast.success(`${res.message}`, {
          position: "top-right",
        });
        return true;
      }
    } catch (error) {
      const message = parseError(error);
      toast.error(message, {
        position: "top-right",
      });
      return false;
    } finally {
      this.isLoading.editInventory = false;
    }
  }
  *editBundle(body: IBundle, bundleId: string) {
    this.isLoading.editBundle = true;
    try {
      delete body._id;
      const res = (yield editBundle(body, bundleId)) as ApiResponse<
        IBundle
      >;
      if (res) {
        this.toggleCreateBundleItemModal();
        toast.success(`${res.message}`, {
          position: "top-right",
        });
        return true;
      }
    } catch (error) {
      const message = parseError(error);
      toast.error(message, {
        position: "top-right",
      });
      return false;
    } finally {
      this.isLoading.editBundle = false;
    }
  }
  *uploadImages(id: string, images: any) {
    this.isLoading.uploadImages = true
    try {
      const body = {
        images: images.map((image: any) => ({
          key: image.key,
          fileUrl: image.fileUrl,
        })),
      }
      const res = (yield uploadImages(id, body)) as ApiResponse<IDrug>;
      if (res.status) {
        this.toggleViewItemImages(id);
        toast.success(`${res.message}`, {
          position: "top-right",
        });
        return true;
      }
    } catch (error) {
      const message = parseError(error);
      toast.error(message, {
        position: "top-right",
      });
      return false;
    }
    finally {
      this.isLoading.uploadImages = false;
    }
  }
  *deleteInventory(id: string) {
    this.isLoading.deleteInventory = true;
    try {
      const res = (yield deleteInventory()) as ApiResponse<IDrug>;
      if (res.status) {
        this.toggleDeleteItemModal(id);
        toast.success(`${res.message}`, {
          position: "top-right",
        });
        return true;
      }
    } catch (error) {
      const message = parseError(error);
      toast.error(message, {
        position: "top-right",
      });
      return false;
    } finally {
      this.isLoading.deleteInventory = false;
    }
  }
  *deleteImageKey(drugId: string, imageKey: string) {
    this.isLoading.deleteImageKey = true;
    try {
      const res = (yield deleteImageKey(drugId, imageKey)) as ApiResponse<IDrug>;
      if (res) {
        toast.success(`${res.message}`, {
          position: "top-right",
        });
        return true;
      }

    } catch (error) {
      const message = parseError(error);
      toast.error(message, {
        position: "top-right",
      });
      return false;
    } finally {
      this.isLoading.deleteImageKey = false;
    }

  }
  *deleteBundle(bundleId: string) {
    this.isLoading.deleteBundle = true;
    try {
      const res = (yield deleteBundle(bundleId)) as ApiResponse<IDrug>;
      if (res.status) {
        this.toggleDeleteBundleModal(bundleId)
        toast.success(`${res.message}`, {
          position: "top-right",
        });
        return true;
      }

    } catch (error) {
      const message = parseError(error);
      toast.error(message, {
        position: "top-right",
      });
      return false;
    } finally {
      this.isLoading.deleteBundle = false;
    }
  }
}
