import { getApiHeaders } from 'utils';
import config from '../../config';
import { client } from '..';
import axios from 'axios';

export const getAllPartners = async (): Promise<ApiResponse<IPartner[] | null> | undefined> => {
  return await client<ApiResponse<IPartner[] | null>>(`${config.AUTH_BASE_URL}/api/partner`, 'GET');
};

export const getPartnerById = async (
  id: string | null
): Promise<ApiResponse<IPartner | null> | undefined> => {
  return await client<ApiResponse<IPartner | null>>(
    `${config.AUTH_BASE_URL}/api/partner/${id}`,
    'GET'
  );
};

export const createPartner = async (data: IPartner): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(`${config.AUTH_BASE_URL}/api/partner`, 'POST', {
    body: data
  });
};

export const addPartnerBulkUpload = async (
  file: File
): Promise<ApiResponse<string> | undefined> => {
  const headers = await getApiHeaders();
  try {
    const response = await axios.post(`${config.AUTH_BASE_URL}/api/partner/upload`, file, {
      headers: {
        ...headers
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error during axios request:', error);
    throw error;
  }
};

export const updatePartner = async (
  data: IPartner,
  partnerId: string
): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/partner/${partnerId}`,
    'PUT',
    { body: data }
  );
};

export const updatePartnerStatus = async (
  partnerId: string,
  status: string
): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/partner/${partnerId}/${status}`,
    'PUT',
    {}
  );
};

export const approveCredit = async (
  partnerId: string,
  amount: number
): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/partner/approve-credit`,
    'POST',
    { body: { partnerId, amount } }
  );
};
export const remitCredit = async (
  partnerId: string,
  amount: number
): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/partner/remit-credit/${partnerId}`,
    'POST',
    { amount }
  );
};

export const getUsedCredit = async (
  query?: string
): Promise<ApiResponse<{ total: number } | null> | undefined> => {
  return await client<ApiResponse<{ total: number } | null>>(
    `${config.AUTH_BASE_URL}/api/partner/used-credit/summary/${query}`,
    'GET'
  );
};
export const getTopGrossingPartners = async (
  query?: string
): Promise<ApiResponse<{ total: number } | null> | undefined> => {
  return await client<ApiResponse<{ total: number } | null>>(
    `${config.AUTH_BASE_URL}/api/order/partners/top-grossing/${query}`,
    'GET'
  );
};

export const getPartnerMembers = async (): Promise<ApiResponse<IMember[] | null> | undefined> => {
  return await client<ApiResponse<IFamilyAndFriends[] | null>>(
    `${config.AUTH_BASE_URL}/api/partner/network/all-members`,
    'GET'
  );
};

export const updatePartnerMember = async (
  body: IMember[] | any,
  id: string
): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/partner/member/${id}`,
    'PUT',
    { body }
  );
};

export const deletePartnerMember = async (id: string): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/partner/member/${id}`,
    'DELETE'
  );
};

export const addPartnerMember = async (
  body: IMember[] | any
): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(`${config.AUTH_BASE_URL}/api/partner/member`, 'POST', {
    body
  });
};

export const updatePartnerMemberAdmin = async (
  body: IMember[] | any,
  id: string,
  partnerId: string
): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/partner/${partnerId}/member/${id}/admin`,
    'PUT',
    { body }
  );
};
export const deletePartnerMemberAdmin = async (
  id: string,
  partnerId: string
): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/partner/${partnerId}/member/${id}/admin`,
    'DELETE'
  );
};

export const getPartnerMembersAdmin = async (
  payload: Record<string, string | number>
): Promise<ApiResponse<string> | undefined> => {
  const { partnerId, searchKey, page, limit } = payload;
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/partner/${partnerId}/members?searchKey=${searchKey}&page=${page}&limit=${limit}`,
    'GET'
  );
};

export const addPartnerMemberAdmin = async (
  body: IMember[] | any,
  partnerId: string
): Promise<ApiResponse<string> | undefined> => {
  return await client<ApiResponse<string>>(
    `${config.AUTH_BASE_URL}/api/partner/${partnerId}/member/admin`,
    'POST',
    { body }
  );
};
