import {
  BoxProps,
  Flex,
  Grid,
  Text,
  VStack,
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react';
import { Routes, orderStatus } from 'constant';
import { IoChatboxOutline } from 'react-icons/io5';
import { MdAdd } from 'react-icons/md';
import { SlOptions } from 'react-icons/sl';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import { parseAmount, formatDateTime, truncateText } from 'utils';
import { formatMilliSecondsToTime, getTagProps } from 'utils/helpers';
import { observer } from 'mobx-react-lite';

export const adminOrderColumn = [
  {
    key: 'username',
    label: 'recipient name',
    headerStyle: { textAlign: 'left' } as BoxProps,
    formatter: (value: string, rowData: any) => {
      return (
        <VStack w="100%" align="start">
          <Flex align="center" gap=".5rem">
            {rowData?.b2b ? (
              <Box
                borderRadius="full"
                bg="primary.main"
                color="white"
                width="30px"
                height="25px"
                textAlign="center"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Text
                  fontSize={{
                    base: '7px',
                    md: '8px'
                  }}
                >
                  {rowData.customerSource.substring(0, 3).toUpperCase()}{' '}
                </Text>
              </Box>
            ) : null}

            <VStack w="100%" align="start">
              <Text align="left">{value}</Text>
              <Grid
                templateColumns={{
                  base: '1fr',
                  md: 'repeat(3, 1fr)'
                }}
                gap={1}
                w="100%"
              >
                {rowData?.tags?.slice(0, 2)?.map((tag: TagItem | string, index: number) => {
                  const { tagLabel, tagColor, textColor } = getTagProps(tag, index);
                  return (
                    <Flex key={index} bg={tagColor} borderRadius="md" w="50px" h="20px" px=".5rem">
                      <Text
                        fontSize={{
                          base: '8px',
                          md: '8px'
                        }}
                        w="100%"
                        color={textColor}
                      >
                        {' '}
                        {truncateText(tagLabel, 6)}
                      </Text>
                    </Flex>
                  );
                })}
                {rowData?.tags?.length > 2 && (
                  <Flex align="center" justify="center">
                    <MdAdd />
                  </Flex>
                )}
              </Grid>
            </VStack>
          </Flex>
        </VStack>
      );
    }
  },
  {
    key: 'orderNumber',
    label: 'order no',
    chakraStyle: { color: '#541AA9', textAlign: 'center' } as BoxProps
  },
  {
    key: 'deliveryAddress.phoneNumber',
    label: 'Phone no',
    notFoundText: 'N/A',
    chakraStyle: { textAlign: 'center' } as BoxProps
  },
  {
    key: 'orderStatus',
    label: 'Order Status',
    conditionalStyles: (rowData: IColumnData) => {
      const status = rowData.orderStatus;
      if (!status) return {};
      return { color: orderStatus[status as keyof typeof orderStatus]?.color };
    },
    formatter: (value: string) => (
      <Text
        p=".5rem .5rem"
        borderRadius="8px"
        bg={orderStatus[value as keyof typeof orderStatus]?.bg}
      >
        {orderStatus[value as keyof typeof orderStatus]?.label}
      </Text>
    ),
    headerStyle: { textAlign: 'center' } as BoxProps,
    chakraStyle: { textAlign: 'center' } as BoxProps
  },
  {
    key: 'isSubscription',
    label: 'Order Type',
    notFoundText: '-',
    formatter: (value: boolean) => `${value ? 'Recurring' : 'One time'}`,
    chakraStyle: { textAlign: 'center' } as BoxProps
  },
  {
    key: 'tat',
    label: 'tat',
    notFoundText: 'N/A',
    chakraStyle: { textAlign: 'center', color: 'primary.main' } as BoxProps,
    formatter: (tat: ITat) => {
      if (tat && tat.totalTAT === 0) return 'N/A';
      return formatMilliSecondsToTime(tat.tatInSeconds as number);
    }
  },
  {
    key: 'orderTotal',
    label: 'order price',
    formatter: (value: number) => parseAmount(value),
    chakraStyle: { textAlign: 'center' } as BoxProps
  },
  {
    key: 'orderSource',
    label: 'order source',
    notFoundText: 'N/A',
    formatter: (value: string) => value || 'N/A',
    chakraStyle: { textAlign: 'center' } as BoxProps
  },
  {
    key: 'receivedTime',
    label: 'order date',
    formatter: (value: string) => {
      const date = formatDateTime(value);
      return <div style={{ width: '100px' }}>{date}</div>;
    },
    chakraStyle: { textAlign: 'center' } as BoxProps
  },
  {
    key: 'deliveredTime',
    label: 'delivery date',
    notFoundText: 'N/A',
    formatter: (date: string | undefined | Date) => {
      if (!date) {
        return 'N/A';
      }
      return (
        <Flex width="100px">
          <Text>{formatDateTime(date as string)}</Text>
        </Flex>
      );
    },
    chakraStyle: { textAlign: 'center' } as BoxProps
  },
  {
    key: 'id',
    label: '',
    formatter: (value: string | undefined, rowData: any) => {
      return (
        <Flex width="60px" align="center" gap=".5rem">
          <Text>{rowData?.comments.length}</Text>
          <IoChatboxOutline size={22} color="#541AA9" cursor="pointer" />{' '}
        </Flex>
      );
    },
    chakraStyle: { textAlign: 'center' } as BoxProps
  },
  {
    key: 'reorder',
    label: '',
    formatter: (value: string, rowData: any) => {
      return (
        <Flex width="60px" align="center" gap=".5rem">
          <PartnerOrderSummaryOptions data={rowData} />
        </Flex>
      );
    },

    chakraStyle: { textAlign: 'center' } as BoxProps
  }
];

const PartnerOrderSummaryOptions = observer((data: any) => {
  const { data: rowData } = data;
  const navigate = useNavigate();

  const {
    AdminOrderStore: { setSelectedOrder, setAdminReorder, canReorderItems, selectedReOrderAddress }
  } = useStore();

  const handleReorderItems = (rowData: IOrder) => {
    setAdminReorder(rowData);
    if (!rowData?.b2b) {
      if (rowData.isFaf) {
        selectedReOrderAddress(rowData?.deliveryAddress);
      }
      navigate(Routes.AdminPlaceOrderForCustomer);
      return;
    }
    rowData?.isFaf
      ? navigate(Routes.AdminPlaceOrderForPartnerMember(rowData.userId, rowData.memberId))
      : navigate(Routes.AdminPlaceOrderForPartner(rowData.userId));
  };

  return (
    <Box>
      <Menu>
        <MenuButton>
          <SlOptions />
        </MenuButton>
        <MenuList>
          <MenuItem
            onClick={() => {
              setSelectedOrder(rowData, rowData.userId);
              navigate(`/admin/${rowData?.orderNumber}/order-summary/details`, {});
            }}
          >
            View order summary
          </MenuItem>
          {canReorderItems(rowData as IOrder) && (
            <MenuItem onClick={() => handleReorderItems(rowData as IOrder)}>
              Re-order Items
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </Box>
  );
});

export const commentReasons: SelectOptions[] = [
  { label: 'Customer has not paid', value: 'Customer has not paid' },
  { label: 'Out of scope', value: 'Out of scope' },
  { label: 'Customer is unreachable', value: 'Customer is unreachable' },
  { label: 'Out of Lagos', value: 'Out of Lagos' },
  { label: 'Delivery fee', value: 'Delivery fee' },
  { label: 'Expensive', value: 'Expensive' },
  { label: 'Still sourcing', value: 'Still sourcing' },
  { label: 'Others', value: 'Others' }
];
export const closeOrderReasons: SelectOptions[] = [
  { label: 'Duplicate order', value: 'Duplicate order' },
  { label: 'Customer changed mind', value: 'Customer changed mind' },
  { label: 'Out of scope', value: 'Out of scope' },
  { label: 'Delivery fee', value: 'Delivery fee' },
  { label: 'Out of Lagos', value: 'Out of Lagos' },
  { label: 'Delayed order confirmation', value: 'Delayed order confirmation' },
  { label: 'OOS/Can’t find item', value: 'OOS/Can’t find item' },
  { label: 'Others', value: 'Others' }
];

export const closeSubscriptionReasons = [
  { label: 'Duplicate order', value: 'Duplicate order' },
  { label: 'Customer changed mind', value: 'Customer changed mind' },
  { label: 'Out of scope', value: 'Out of scope' },
  { label: 'Delivery fee', value: 'Delivery fee' },
  { label: "I don't need the meds anymore", value: "I don't need the meds anymore" },
  { label: 'Others', value: 'Others' }
];
export const refundReasons: SelectOptions[] = [
  { label: 'Incorrect Medication Received', value: 'Incorrect Medication Received' },
  { label: 'Expired or Short Expiry Date', value: 'Expired or Short Expiry Date' },
  { label: 'Damaged Packaging', value: 'Damaged Packaging' },
  { label: 'Incomplete Order', value: 'Incomplete Order' },
  { label: 'Prescription required', value: 'Prescription required' },
  { label: 'Delivery Issues', value: 'Delivery Issues' },
  { label: 'Couldn’t source preferred medication', value: 'Couldn’t source preferred medication' },
  { label: 'Customer changed mind', value: 'Customer changed mind' },
  { label: 'Billing Errors', value: 'Billing Errors' },
  { label: 'Temperature Sensitivity', value: 'Temperature Sensitivity' },
  { label: 'Others', value: 'Others' }
];

export const filterStatus = [
  {
    label: 'All',
    value: 'All'
  },
  {
    label: 'Awaiting confirmation',
    value: 'Pending'
  },
  {
    label: 'Awaiting payment',
    value: 'Awaiting Payment'
  },
  {
    label: 'Paid',
    value: 'Paid'
  },
  {
    label: 'Delivered',
    value: 'Delivered'
  },
  {
    label: 'Dispatched',
    value: 'Dispatched'
  },
  {
    label: 'Closed',
    value: 'Closed'
  },
  {
    label: 'Cancelled',
    value: 'Cancelled'
  }
];
export const orderTypes = [
  {
    label: 'All',
    value: 'All'
  },
  {
    label: 'Recurring order',
    value: true
  },
  {
    label: 'One time',
    value: false
  }
];
export const orderCustomerType = [
  {
    label: 'All',
    value: 'All'
  },
  {
    label: 'Guest customer',
    value: true
  },
  {
    label: 'Returning customer',
    value: false
  }
];
export const orderCSVMapping: Mapping = {
  deliveryAddress: {
    header: 'Recipient Name',
    formatter: (info) => (info ? info?.firstName + ' ' + info?.lastName : 'N/A')
  },
  orderNumber: { header: 'Order Number' },
  'deliveryAddress.email': {
    header: 'Email Address',
    formatter: (info) => (info ? info?.email : 'N/A')
  },
  'deliveryAddress.phoneNumber': {
    header: 'Phone Number',
    formatter: (info) => info || 'N/A'
  },
  orderStatus: { header: 'Order Status', formatter: (info) => (info === 'Pending' ? 'Awaiting Confirmation' : info) },
  orderSource: { header: 'Order Source' },
  orderTotal: { header: 'Order Price' },
  createdAt: {
    header: 'Order Date',
    formatter: (date) =>
      new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      })
  }
};


export const orderSourceOptions = [
  { value: 'Webapp', label: 'Webapp' },
  { value: 'Whatsapp', label: 'Whatsapp' },
  { value: 'Google ad', label: 'Google ad' },
  { value: 'Instagram', label: 'Instagram' },
  { value: 'Facebook', label: 'Facebook' },
  { value: 'Live chat', label: 'Live chat' }
];
export const progressOptions = [
  { value: 'SOURCING', label: 'Sourcing' },
  { value: 'COMPLETED', label: 'Completed' },
  { value: 'PARTNER_DEPENDENCY', label: 'Partner depedency' }
];
export const orderStageOptions = [
  { value: 'CS', label: 'Customer service' },
  { value: 'PS', label: 'Partner success' },
  { value: 'VM', label: 'Vendor management' },
  { value: 'DISPENSING', label: 'Dispensing' },
  { value: 'PICK_UP', label: 'Logistics - Pick up' },
  { value: 'DELIVERY', label: 'Logistics - Delivery' },
  { value: 'IR', label: 'Issues Resolution' },
  { value: 'CO', label: 'Completed' }
];

export const adminOrderFilterOptions = [
  {
    category: 'Status',
    id: 'orderStatus',
    options: [
      {
        label: 'Awaiting confirmation',
        value: 'Pending'
      },
      {
        label: 'Awaiting payment',
        value: 'Awaiting Payment'
      },
      {
        label: 'Paid',
        value: 'Paid'
      },
      {
        label: 'Delivered',
        value: 'Delivered'
      },
      {
        label: 'Dispatched',
        value: 'Dispatched'
      },
      {
        label: 'Closed',
        value: 'Closed'
      },
      {
        label: 'Cancelled',
        value: 'Cancelled'
      },
      {
        label: 'Active',
        value: 'Active'
      },
      {
        label: 'Paused',
        value: 'Paused'
      },
      {
        label: 'Pickup',
        value: 'Pickup'
      },
      {
        label: 'Logged',
        value: 'isLogged'
      },
      {
        label: 'Not Logged',
        value: 'notLogged'
      },
      {
        label: 'Refunded',
        value: 'Refunded'
      },
      {
        label: 'Refund Processing',
        value: 'Refund Processing'
      },
      {
        label: 'Partially Refunded',
        value: 'Partially Refunded'
      },
      {
        label: 'Ready to Dispatch',
        value: 'Ready to Dispatch'
      },
      {
        label: 'Credit Approved',
        value: 'Credit Approved'
      }
    ]
  },
  {
    category: 'Source',
    id: 'customerSource',
    options: [
      {
        label: 'Webapp',
        value: 'Webapp'
      },
      {
        label: 'Whatsapp',
        value: 'Whatsapp'
      },
      {
        label: 'Google',
        value: 'Google ad'
      },
      {
        label: 'Instagram',
        value: 'Instagram'
      },
      {
        label: 'Referrals',
        value: 'Referral'
      },
      {
        label: 'Facebook',
        value: 'Facebook'
      },
      { label: 'Live chat', value: 'Live chat' }
    ]
  },
  {
    category: 'Drop of reason',
    id: 'comment',
    options: [
      {
        label: 'Out of scope',
        value: 'Out of scope'
      },
      {
        label: 'Customer changed mind',
        value: 'Customer changed mind'
      },
      {
        label: 'Delivery fee',
        value: 'Delivery fee'
      },
      {
        label: 'Out of Lagos',
        value: 'Out of Lagos'
      },
      {
        label: 'OOS/Can’t find item',
        value: 'OOS/Can’t find item'
      },
      {
        label: 'Delayed order confirmation',
        value: 'Delayed order confirmation'
      },
      {
        label: 'Others',
        value: 'Others'
      }
    ]
  },
  {
    category: 'Type',
    id: 'orderType',
    options: [
      {
        label: 'One time',
        value: false
      },
      {
        label: 'Recurring',
        value: true
      }
    ]
  },
  {
    category: 'Customers',
    id: 'customers',
    options: [
      { label: 'New customer', value: true },
      {
        label: 'Returning customer',
        value: false
      }
    ]
  },
  {
    category: 'Order mode',
    id: 'customerType',
    options: [
      {
        label: 'Signed in order',
        value: false
      },
      {
        label: 'Guest order',
        value: true
      }
    ]
  }
];

export const defaultTags = Array.from({ length: 10 }, (_, index) => {
  const colors = [
    { color: '#fff', bgColor: '#FA7F9A' },
    { color: '#fff', bgColor: '#00C7B8' },
    { color: '#107998', bgColor: '#B6E9F9' },
    { color: 'black', bgColor: '#f8e682' },
    { color: '#333', bgColor: '#FFD700' },
    { color: '#fff', bgColor: '#8A2BE2' },
    { color: '#000', bgColor: '#FF6347' },
    { color: '#fff', bgColor: '#32CD32' },
    { color: '#333', bgColor: '#FFA500' },
    { color: '#fff', bgColor: '#4682B4' }
  ];

  return {
    id: index + 1,
    color: colors[index]?.color || '#fff', // Default to '#fff' if color is undefined
    bgColor: colors[index]?.bgColor || '#FA7F9A' // Default to '#FA7F9A' if bgColor is undefined
  };
});

export const refundOptions = [
  {
    title: 'Manual refund',
    subTitle: 'Manual',
    refundType: 'manual'
  },
  {
    title: 'Wallet refund',
    subTitle: 'Wallet',
    refundType: 'wallet'
  },
  {
    title: 'Bank refund',
    subTitle: 'Paystack',
    refundType: 'third party'
  }
];
export const deductWalletReasons = [
  { label: 'Out of Pocket Purchase Done', value: 'Out of Pocket Purchase Done' },
  { label: 'Location Change', value: 'Location Change' },
  { label: 'HMO Cancellation Request', value: 'HMO Cancellation Request' },
  { label: 'Wrong Medications', value: 'Wrong Medications' },
  { label: 'Receiver Rejected Order', value: 'Receiver Rejected Order' }
];