import { Box, Card, Flex, Input, Button, Text } from '@chakra-ui/react';
import React from 'react';
import Select from 'react-select';
import { observer } from 'mobx-react-lite';
import { deductWalletReasons } from 'data/order';
import { useStore } from 'store';

const DeductWallet = ({
  submit,
  isLoading
}: {
  submit: (amount: number, reason: string) => void;
  isLoading: boolean;
}) => {
  const {
    FinanceStore: { deductionForm, setDeduction }
  } = useStore();

  const { amount, reason } = deductionForm;

  const handleDeductFromWallet = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    submit(amount, reason);
  };

  const setField = (key: string, value: any) => {
    setDeduction({ key, value });
  };
  const isDisabled = isNaN(amount) || amount < 1 || amount === null || reason === '';

  return (
    <>
      <Box>
        <Text fontWeight="450" fontSize="15px">
          Deduct wallet
        </Text>
      </Box>
      <Card border="1.5px solid #F5F5F5" p=".6rem" w="100%" mb={{ base: '10rem', md: '1rem' }}>
        <Box w="100%">
          <Text
            fontSize={{
              base: '13px',
              md: '14px'
            }}
            mb={1}
          >
            Reason{' '}
          </Text>

          <Select
            options={deductWalletReasons}
            isSearchable={false}
            onChange={(selectedOption: any) => {
              if (selectedOption) {
                const value = selectedOption.value;
                setField('reason', value);
              }
            }}
            value={
              reason ? { value: reason, label: reason } : { value: '', label: 'Select a reason' }
            }
            menuPlacement="auto"
            menuPosition="fixed"
            styles={{
              menu: (provided: any) => ({
                ...provided,
                maxHeight: '300px',
                overflowY: 'auto',
                fontSize: '14px'
              }),
              control: (provided: any) => ({
                ...provided,
                fontSize: '13px',
                background: '#F3F5F7'
              })
            }}
          />
        </Box>
        <Box
          fontSize={{
            base: '12px',
            md: '14px'
          }}
          mt="1rem"
        >
          <Text
            fontSize={{
              base: '13px',
              md: '14px'
            }}
          >
            Amount
          </Text>
          <form onSubmit={handleDeductFromWallet}>
            <Flex gap="1rem">
              <Input
                name="amount"
                py="1.4rem"
                bg="#F3F5F7"
                type="number"
                min={0}
                value={amount}
                onChange={(e: any) => setField('amount', e.target.value)}
                fontSize={{ base: '1rem', md: '0.875rem' }}
              />
              <Button isDisabled={isDisabled} isLoading={isLoading} type="submit">
                Deduct
              </Button>
            </Flex>
          </form>
        </Box>
      </Card>
    </>
  );
};

export default observer(DeductWallet);
